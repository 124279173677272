export function toggleMapSwitch() {
  let toggleBtn = $('.js-toggle-map');
  if(toggleBtn.length) {
    $(toggleBtn).on('click', function() {
      let href = $(this).data('map-href');
      if(href) {
        window.location.href = href;
      }     
    });
  }
}
