import slick from 'slick-carousel';
import debounce from 'debounce';
import { isElementInViewport } from './checkViewport';
import PubSub from 'pubsub-js';
import { BODY, ISHIDDEN } from '../_constants';
import { addBodyHidden, removeBodyHidden } from './bodyHiddenPosition.js';
import { myLazyLoad } from './lazyLoading.js';

export function sliders() {


  // custom common arrow btns
  let commonNextArrow = `<div class="next">
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.339844 10.59L4.91984 6L0.339844 1.41L1.74984 0L7.74984 6L1.74984 12L0.339844 10.59Z" fill="#009999"/></svg></div>`;
  let commonPrevArrow = `<div class="prev">
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.339844 10.59L4.91984 6L0.339844 1.41L1.74984 0L7.74984 6L1.74984 12L0.339844 10.59Z" fill="#009999"/></svg>
 </div>`;


  // slider in popular venue section
  let sliderVenue = $('.js-venue-slider');

  $(sliderVenue).slick({
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        rows: 2
      }
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 2,
        autoplay: false,
        rows: 2
      }
    }
    ]
  });

  $('.js-booking-slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.js-booking-slider-nav',
    responsive: [{
      breakpoint: 991,
      settings: {
        arrows: true,
        nextArrow: commonNextArrow,
        prevArrow: commonPrevArrow
      }
    }]
  });
  $('.js-booking-slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.js-booking-slider-for',
    dots: false,
    prevArrow: commonPrevArrow,
    nextArrow: commonNextArrow,
    centerMode: false,
    infinite: true,
    focusOnSelect: true
  });


  // slider in booking similar
  let bookingSimilarSlider = $('.js-booking-similar-slider');

  $(bookingSimilarSlider).slick({
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    swipe: false,
    responsive: [{
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        rows: 1
      }
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        rows: 1
      }
    }
    ]
  });


  // slider in lp-reasons section
  let sliderReasonsLP = $('.js-advantages-slider');

  reinitAutoplay(sliderReasonsLP);

  $(sliderReasonsLP).on('destroy', function(ev, slick) {
    reinitSlider(this, slick.options);
  }).slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 2500,
    responsive: [{
      breakpoint: 599,
      settings: 'unslick'
    }]
  });


  // slider in reasons-video section
  let sliderReasonsVideoLP = $('.js-review-videos-mob-slider');
  reinitAutoplay(sliderReasonsVideoLP);
  $(sliderReasonsVideoLP).on('destroy', function(ev, slick) {
    let popupLink = $(this).find('.js-popup-link');
    reinitSlider(this, slick.options, popupLink);
  }).slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 2500,
    responsive: [{
      breakpoint: 599,
      settings: 'unslick'
    }]
  });


  // slider in steps
  let steps = $('.js-steps-slider');

  reinitAutoplay(steps);
  $(steps).on('destroy', function(ev, slick) {
    reinitSlider(this, slick.options);
  }).slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 2500,
    responsive: [{
      breakpoint: 767,
      settings: 'unslick'
    }]
  });


  // slider in reasons-video section
  let cards = $('.js-cards-slider');
  reinitAutoplay(cards);
  $(cards).on('destroy', function(ev, slick) {
    reinitSlider(this, slick.options);
  }).slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [{
      breakpoint: 767,
      settings: 'unslick'
    }]
  });

  // slider in dashboard first time menu
  let chooseCardsDashboard = $('.js-choose-card-slider');
  $(chooseCardsDashboard).on('destroy', function(ev, slick) {
    reinitSlider(this, slick.options);
  }).slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [{
      breakpoint: 767,
      settings: 'unslick'
    }]
  });


  // slider residence-cards
  let residenceCards = $('.js-residence-card-slider');

  $(residenceCards).slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
    // nextArrow: commonNextArrow,
    // prevArrow: commonPrevArrow
  });




  // slider in booking
  let sliderHeroBooking = $('.js-hero-booking-slider');

  $(sliderHeroBooking).slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  });

  // slider in popup
  let openGalleryPopupBtn = $('.js-open-gallery-popup');

  $(openGalleryPopupBtn).on('click', function(e) {
    e.preventDefault();
    myLazyLoad.loadAll();
    let popupSlider = $('.js-popup-gallery-slider');
    let popupSliderThumbs = $('.js-popup-gallery-thumbnails');
    let activeSlide = parseInt(sliderHeroBooking.find('.slick-active').attr('data-slick-index'));
    let popupSliderCounter = $('.js-popup-gallery-counter');

    function initNewSlider({ newClass, wrapEl, slider, closeBtn }) {
      let newSlider = sliderHeroBooking
        .find('.slick-slide')
        .not('.slick-cloned')
        .find('.slider-slide')
        .clone()
        .addClass(newClass)
        .wrap(wrapEl)
        .parent();
      if(closeBtn) {
        newSlider.find('.popup__close').removeClass('is-hidden');
      }
      slider.html(newSlider);
    }

    initNewSlider({
      newClass: 'popup-slide__inner',
      wrapEl: '<div class="popup-slide"></div>',
      slider: popupSlider,
      closeBtn: true
    });
    initNewSlider({
      newClass: 'slide-thumb__inner',
      wrapEl: '<div class="slide-thumb"></div>',
      slider: popupSliderThumbs
    });


    popupSlider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      popupSliderCounter.text(i + '/' + slick.slideCount);
    });



    popupSlider.slick({
      initialSlide: activeSlide,
      asNavFor: popupSliderThumbs,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: commonPrevArrow,
      nextArrow: commonNextArrow,
    });

    popupSliderThumbs.slick({
      asNavFor: popupSlider,
      initialSlide: 0,
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: true,
      centerMode: true,
      infinite: true
    });

    let bodyHiddenStatus = 0;
    $('.js-popup-overlay, .js-close-popup').on('click', function(e) {
      e.stopPropagation();

      $(popupSlider).slick('unslick');
      $(popupSliderThumbs).slick('unslick');

      let parent = $(this).parents('.js-popup');
      parent.fadeOut(200);
      if (parent.attr('data-popup') === 'popup-gallery') {
        PubSub.publish('close-popup-gallery');
      };

      if (bodyHiddenStatus === 1) {
        bodyHiddenStatus = 0;
      } else {
        BODY.removeClass(ISHIDDEN);
        removeBodyHidden();
      };
    });

  });



  // for arrows outside slider wrapper
  function sliderArrows(slider, block) {
    for(let i=0; i<slider.length; i++) {
      let nextBtnSlider = $(slider[i]).closest(block).find('.next');
      let prevBtnSlider = $(slider[i]).closest(block).find('.prev');
      $(nextBtnSlider).click(function() {
        $(slider[i]).slick('slickNext');
      });
      $(prevBtnSlider).click(function() {
        $(slider[i]).slick('slickPrev');
      });
    }
  }

  sliderArrows(residenceCards, '.js-residence-card');
  sliderArrows(sliderHeroBooking, '.booking-hero');

  // function for reinit slider on resize
  function reinitSlider(target, slickOptions, sliderPopupLink) {
    let carousel = $(target),
      reinit = debounce(function() {
        /*
         * slick.activeBreakpoint will tell the breakpoint
         * at which the carousel was destroyed.
         */
        if (slick.activeBreakpoint < window.innerWidth) {
          return;
        }

        // Re-initialize with the old settings.
        carousel.slick(slickOptions);

        // reinit events if popup links inside slider
        if(sliderPopupLink) {
          $(sliderPopupLink).on('click', function(e) {
            e.preventDefault();

            let $this = $(this),
              attr = $this.attr('data-popup');
            let target = $(`.js-popup[data-popup="${attr}"]`);

            $('.js-popup').fadeOut(200);
            target.fadeIn(300);
            addBodyHidden();
          });
        }
        // Remove this event listener.
        window.removeEventListener('resize', reinit);
      }, 500);

    // Assign our debounced callback to window.resize.
    window.addEventListener('resize', reinit);
  }
  // function for reinit slider on resize
  function reinitAutoplay(target) {
    var scrollTimeout;
    var throttle = 50;

    // function for reinit slider on resize
    $(window).on('scroll', function() {
      if (!scrollTimeout) {

        scrollTimeout = setTimeout(function() {
          if(target.length) {
            if(isElementInViewport(target)) {
              if($(target).get(0).slick.options.autoplaySpeed ) {
                $(target).get(0).slick.options.autoplay = true;
                target.slick('slickPlay');
              }
            } else {
              $(target).get(0).slick.options.autoplay = false;
              target.slick('slickPause');
            }
          }
          scrollTimeout = null;
        }, throttle);
      }
    });
  }
}
