export const ValidationForm = () => {
  const target = $('.js-validator-email');

  if (target.length) {
    $.validator.addMethod('emailExt', value => {
      const patternEmail = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/.test(value);
      return patternEmail;
    }, 'Please enter valid E-mail');
    $('.js-validation').each((i, el) => {
      $(el).validate({
        errorElement: 'div',
        errorPlacement: (error, element) => {
          if (element.is(':checkbox')) {
            error.insertAfter(element.parent()).addClass('error-checkbox');
          } else {
            error.insertAfter(element);
          }
        },
      });
    });

    target.rules('add', {
      emailExt: true,
    });
  }
};
