import Cookies from 'js-cookie';
import { env } from '../envVariables.js.erb';

export const bookingCountdown = () => {
  const countdownEl = $('.js-countdown');

  if (countdownEl.length > 0) {
    const countdownTime = env.INITIAL_BOOKING_EXPIRATION_TIME_IN_MINUTES > 9
      ? env.INITIAL_BOOKING_EXPIRATION_TIME_IN_MINUTES
      : '0' + env.INITIAL_BOOKING_EXPIRATION_TIME_IN_MINUTES;
    const targetMinutes = countdownEl.find('.js-countdown-minutes');
    const targetSeconds = countdownEl.find('.js-countdown-seconds');
    const choicePopup = $('[data-popup="checkout"]');
    const newBookingPopup = $('[data-popup="cancel-booking"]');
    const resetTimerBtn = choicePopup.find('.js-countdown-reset-timer');
    const cancelBookingBtn = $('.js-cancel-booking');
    const deleteBookingPath = cancelBookingBtn.data('delete-booking-path');
    const redirectBookingPath = cancelBookingBtn.data('redirect');
    const bookingId = +resetTimerBtn.data('booking-id');
    const extendBookingPath = resetTimerBtn.data('extend-booking-path');
    let isExtended = resetTimerBtn.data('is-extended');
    const cookieTimerStarted = 'timer-start-time-for-id-' + bookingId;

    function showChoicePopup() {
      choicePopup.show()
    }

    function showNewBookingPopup() {
      printNumber(0, targetSeconds);
      choicePopup.remove();
      newBookingPopup.show()
    }

    function printNumber(time, targetElement) {
      targetElement.text(time > 9 ? time : '0' + time);
    }

    // Update the count down every 1 second
    function Counter() {
      let counter = setInterval(() => {

        // check if we have first visit time written to Cookies
        if (!Cookies.get(cookieTimerStarted)) {
          Cookies.set(cookieTimerStarted, Date.now(), { expires: 1 });
        }

        const timerStarted = Cookies.get(cookieTimerStarted);
        let countdownTimerTime = Date.parse('1970-01-01T00:' + countdownTime + ':00Z');
        let countdownDateTime = +timerStarted + +countdownTimerTime;
        let now = Date.now();

        if (now < countdownDateTime) {

          // Find the remaining milliseconds between now an the count down date
          let remainingMilliseconds = countdownDateTime - now;

          // Time calculations for minutes and seconds
          let minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

          printNumber(minutes, targetMinutes);
          printNumber(seconds, targetSeconds);

          if (minutes === 0 && seconds === 0) {
            showNewBookingPopup();
            deleteBooking();
          } else if (minutes < 3 && !isExtended) {
            showChoicePopup();
          } else if (minutes < 5) {
            countdownEl.addClass('warning');
          } else {
            countdownEl.removeClass('warning');
          }

          // If the count down is finished, delete the button
          if (remainingMilliseconds < 0) {
            clearInterval(counter);
          }

        } else {
          clearInterval(counter);
          showNewBookingPopup();
        }
      }, 1000);
    }

    resetTimerBtn.on('click', e => {
      e.preventDefault();

      if (!isExtended) {
        $.ajax({
          type: 'put',
          url: extendBookingPath,
          contentType: "text/plain",
          data: {
            id: bookingId
          },
          success: () => {
            Cookies.remove(cookieTimerStarted);
            Counter();
            isExtended = true;
            choicePopup.fadeOut(200);
          },
          error: () => {
            console.log('=====', 'error');
          },
        })
      }
    });

    cancelBookingBtn.on('click', e => {
        e.preventDefault();

        $.ajax({
          type: 'delete',
          url: deleteBookingPath,
          contentType: "text/plain",
          data: {
            id: bookingId
          },
          success: () => {
            window.location.href = redirectBookingPath;
          },
          error: () => {
            console.log('=====', 'error');
          },
        })
      });

    function deleteBooking() {
      $.ajax({
        type: 'delete',
        url: deleteBookingPath,
        contentType: "text/plain",
        data: {
          id: bookingId
        },
        success: () => {
          console.log('-----', 'success');
        },
        error: () => {
          console.log('=====', 'error');
        },
      })
    }

    Counter();
  }
};
