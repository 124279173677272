import { env } from '../envVariables.js.erb';

export function emailSubscription() {
  $('.js-subscription-form').each((index, item) => {
    let form = $(item);
    let subscriptionForm = form.find('.js-email-subscription-form');
    let email = form.find('input');
    let errorBlock = form.find('.js-subscription-error');
    let successBlock = form.find('.js-subscription-success');
    let loadingBlock = form.find('.js-subscription-loading');

    subscriptionForm.on('submit', function (e) {
      e.preventDefault();

      if (validateEmail()) {
        loadingBlock.css('display', 'block');
        subscriptionForm.find('.recaptcha').removeClass('hidden')
      }
    });

    $('.js-recaptcha').on('captcha-change', () => {
      $.ajax({
        type: "POST",
        url: '/email_subscriptions',
        data: {email: email.val()},
        complete: function (data) {
          loadingBlock.css('display', 'none');
          subscriptionForm.css('display', 'none');
          successBlock.css('display', 'block');
        },
        dataType: 'JSON'
      });
    });

    function validateEmail() {
      let filter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(email.val())) {
        errorBlock.text('Please provide a valid email address');
        email.focus();
        return false
      } else {
        errorBlock.text('');
        return true
      }
    }
  })
}