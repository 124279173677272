export function additionalFilters() {
  let additionalFilter = $('.js-additional-filter');

  if (additionalFilter.length > 0) {
    let showFiltersBtn = $('.js-additional-filters-btn');

    showFiltersBtn.on('click', () => {
      if (additionalFilter.hasClass('hidden')) {
        additionalFilter.removeClass('hidden');
        showFiltersBtn.text('Hide Filters');
      } else {
        additionalFilter.addClass('hidden');
        showFiltersBtn.text('Show Filters');
      }
    });

    let locationInput = $('.js-filter-location');
    let radiusInput = $('.js-filter-radius');

    const isRadiusDisabled = () => {
      if (locationInput.val().length === 0) {
        radiusInput.val('');
        locationInput.addClass('active').attr('placeholder', 'Please fill this field').focus();
      }
    };

    radiusInput.on('input', () => {
      isRadiusDisabled();
    });

    locationInput.on('input', () => {
      locationInput.removeClass('active').attr('placeholder', 'Fort Lauderdale, FL');
    })
  }
}
