import Cookies from 'js-cookie';
import { env } from '../envVariables.js.erb';

export function mailchimp() {
  let mailchimpPopup = $('.js-popup-mailchimp');

  if (mailchimpPopup.length) {
    let popupDelay = 20000;
    let animationDuration = 200;
    let closeBtn = $('.js-close-mailchimp');

    let form = $('.js-mailchimp-form');
    let email = $('.js-mailchimp-email');
    let firstName = $('.js-mailchimp-first-name');
    let submitBtn = $('.js-mailchimp-submit');
    let errorBlock = $('.js-mailchimp-error');
    let successBlock = $('.js-mailchimp-success');
    let loadingBlock = $('.js-mailchimp-loading');
    let recaptcha = $('.js-recaptcha');

    if (!Cookies.get('rejected')) {
      setTimeout(() => {
        mailchimpPopup.fadeIn(animationDuration);
      }, popupDelay);
    }

    closeBtn.on('click', function () {
      $(this).parents('.js-popup').fadeOut(animationDuration);
      Cookies.set('rejected', true, { expires: 5 });
    })

    submitBtn.on('click', function (e) {
      e.preventDefault();

      if (validateEmail()) {
        loadingBlock.css('display', 'block');
        $.ajax({
          type: "POST",
          url: '/email_subscriptions',
          data: {email: email.val(), first_name: firstName.val(), token: recaptcha.val()},
          complete: function (data) {
            if (data.status === 401 || data.responseText === 'unauthorized') {
              errorBlock.text('Please prove that you are not a bot');
              recaptcha.val('');
            }

            else if (data.status === 201 || data.responseText === 'ok' || data.status === 400 || data.responseText === 'failed') {
              loadingBlock.css('display', 'none');
              form.css('display', 'none');
              successBlock.css('display', 'block');
              Cookies.set('addedEmail', true, { expires: ( 3650 )});
              setTimeout(() => {
                mailchimpPopup.fadeOut(animationDuration);
              }, 6000);
            }
          },

          dataType: 'JSON'
        });
      }
    });

    function validateEmail() {
      let filter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(email.val())) {
        errorBlock.text('Please provide a valid email address');
        email.focus();
        return false
      } else {
        errorBlock.text('');
        return true
      }
    }
  }
}
