import { ISOPEN } from '../_constants';
import { copyToClipboard } from './copyToClipBoard.js';

export function copyLink() {
  let openCopyBtn = $('.js-copy-btn-open');
  let copyBlock = $('.js-copy-link');
  let copyLinkMessage = $('.js-copy-link-message');
  let copyText = copyBlock.find('.js-copy-text');
  let copyLink = copyBlock.find('.js-copy-text-btn');

  openCopyBtn.on('click', function(e) {
    e.preventDefault();
    copyBlock.addClass(ISOPEN);
  });
  copyLink.on('click', function(e) {
    e.preventDefault();    
    copyToClipboard(copyText[0]);
    copyLinkMessage.css('display', 'block');

    setTimeout(function () {
      copyLinkMessage.css('display', 'none');
    }, 5000);

  });
    
}
