import { createDateRange } from './createDateRange';
import { dataDaysWithMultipleRanges } from './selectors';

export function calculateBookingPrice() {
  let priceWrap = $('.js-price-wrap');
  let priceDesc = priceWrap.find('.js-price-desc');
  let priceSumEl = priceWrap.find('.js-price-sum');
  let priceDiscountWrap = priceWrap.find('.js-price-discount-wrap');
  let priceDiscountEl = priceWrap.find('.js-price-discount');
  let pricePersonalDiscountWrap = priceWrap.find('.js-price-personal-discount-wrap');
  let pricePersonalDiscountEl = priceWrap.find('.js-price-personal-discount');
  let priceCleaningWrap = priceWrap.find('.js-price-cleaning-wrap');
  let priceCleaningEl = priceWrap.find('.js-price-cleaning');
  let priceTotalEl = priceWrap.find('.js-price-total');
  let submitBtn = $('.js-request-btn');

  let datepickerDataInput = $('input.datepicker-data');
  let dataDisabledDates = datepickerDataInput.data('disabled-dates') ? datepickerDataInput.data('disabled-dates') : [new Date()];
  let dataDisabledDaysOfWeek = datepickerDataInput.data('disabled-days') ? datepickerDataInput.data('disabled-days') : [];

// vars for getting selected data
  let bookingForm = $('.js-booking-form');
  let bookingFormStartDate = bookingForm.find('.js-form-start-date');
  let bookingFormEndDate = bookingForm.find('.js-form-end-date');
  let bookingFormFromTime = bookingForm.find('.js-form-from-time');
  let bookingFormToTime = bookingForm.find('.js-form-to-time');
  let bookingFormType = bookingForm.find('.js-form-type');

// initial venue numbers
  let discountPercent = +bookingForm.data('discount') || 0;
  let personalDiscountPercent = +bookingForm.data('private-discount') || 0;
  let fullDayRate = +bookingForm.data('full-day-rate') || 0;
  let fullDayRateWizard = +bookingForm.data('full-day-rate-wizard') || 0;
  let hourlyRate = +bookingForm.data('hourly-rate');
  let hourlyRateWizard = +bookingForm.data('hourly-rate-wizard');
  let cleaningFee = +bookingForm.data('cleaning-fee') || 0;

  let startDate, endDate, fromTime, toTime, type;

  if (discountPercent === 0) {
    priceDiscountWrap.css('display', 'none');
  }
  if (personalDiscountPercent === 0) {
    pricePersonalDiscountWrap.css('display', 'none');
  }
  if (cleaningFee === 0) {
    priceCleaningWrap.css('display', 'none');
  }
  if (fullDayRate !== 0) {
    $('.js-toggle-time-selects').css('display', 'block');
  }

  bookingForm.on('hidden-input-change', function () {
    startDate = bookingFormStartDate.val();
    endDate = bookingFormEndDate.val();
    fromTime = bookingFormFromTime.val();
    toTime = bookingFormToTime.val();
    type = bookingFormType.val();

    if (startDate.length && endDate.length && fromTime.length && toTime.length && type.length) {
      calculatePrice();
      priceWrap.addClass('is-shown');
      submitBtn.removeClass('is-disabled').attr('disabled', false);
    } else {
      priceWrap.removeClass('is-shown');
      submitBtn.addClass('is-disabled').attr('disabled', true);
    }
  });

  function calculatePrice() {
    let priceSum, discount, personalDiscount, priceTotal;

// calculations for single day
    if (type === 'single') {
      let hoursCount = +toTime - +fromTime;
      hoursCount = hoursCount < 0 ? 0 : hoursCount;
      priceSum = hoursCount * hourlyRate;
      discount = discountPercent * hoursCount * hourlyRateWizard / 100;
      personalDiscount = personalDiscountPercent * hoursCount * hourlyRateWizard / 100;
      priceTotal = priceSum - discount - personalDiscount + cleaningFee;

      priceDesc.html(`$${(hourlyRate / 100).toFixed(2)}/hr x ${hoursCount} hour${hoursCount > 1 ? 's' : ''}:`);
    } else {
      let datesArray = createDateRange(startDate, endDate);
      let woDisabledDates = [];

      // remove disabled days
      for (let i = 0; i < datesArray.length; i++) {
        if (dataDisabledDates.indexOf(datesArray[i]) === -1 && dataDaysWithMultipleRanges.indexOf(new Date(datesArray[i]).getDay()) === -1) {
          woDisabledDates.push(new Date(datesArray[i]).getUTCDay());
        }
      }

      // create array of day indexes to count actual selected number of dates
      let countDaysArray = woDisabledDates;
      for (let i = 0; i < dataDisabledDaysOfWeek.length; i++) {
        countDaysArray = countDaysArray.filter((e) => { return e !== dataDisabledDaysOfWeek[i] })
      }

      let daysCount = countDaysArray.length;

      priceDesc.html(`$${(fullDayRate / 100).toFixed(2)}/day x ${daysCount} day${daysCount > 1 ? 's' : ''}:`);
      priceSum = fullDayRate * daysCount;
      discount = discountPercent * daysCount * fullDayRateWizard / 100;
      personalDiscount = personalDiscountPercent * daysCount * fullDayRateWizard / 100;
      priceTotal = priceSum - discount - personalDiscount + cleaningFee;
    }

    priceSumEl.html(`$${(priceSum / 100).toFixed(2)}`);
    priceDiscountEl.html(`- $${(discount / 100).toFixed(2)}`);
    pricePersonalDiscountEl.html(`- $${(personalDiscount / 100).toFixed(2)}`);
    priceCleaningEl.html(`$${(cleaningFee / 100).toFixed(2)}`);
    priceTotalEl.html(`$${(priceTotal / 100).toFixed(2)}`);
  }
}