export function showFilters() {
  let toggleFiltersBtn = $('.js-show-filters');

  if (toggleFiltersBtn.length) {
    let filtersEl = $('.js-show-filters-target');

    toggleFiltersBtn.on('click', function () {
      if (filtersEl.hasClass('opened')) {
        toggleFiltersBtn.text('Show Filters');
      } else {
        toggleFiltersBtn.text('Hide Filters');
      }
      filtersEl.toggleClass('closed opened')
    });
  }
}
