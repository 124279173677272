import { WINDOW, ISFIXED } from '../_constants';
import { getElemHeight } from './getElemSize.js';

export function fixedHeader() {    
  let popupVisible = $('.js-popup').is(':visible');
  if (WINDOW.scrollTop() > getElemHeight('.js-header') || popupVisible) {
    $('.js-header').addClass(ISFIXED);
  } else {
    $('.js-header').removeClass(ISFIXED);
  }    
}
