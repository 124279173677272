import formatTime from './formatTime';

export default function createAvailableHoursArray(availables, type, selectedFrom = null, selectedTo = null, range = null) {
  let availablesArrayFrom = [];
  let availablesArrayTo = [];
  let bookingForm = $('.js-booking-form');
  let minHours = +bookingForm.data('min-hours');

  availables.map(({ from, to }, index) => {
    availablesArrayFrom[index] = [];
    availablesArrayTo[index] = [];

    if (type === 'from-to') {
      let hourFrom = selectedFrom || availables[index].from;
      let hourTo = selectedTo || availables[index].to;

      if (range === null) {
        for (let i = from; i <= to; i++) {
          availablesArrayFrom[index].push([i, formatTime(i), !(i + minHours <= hourTo), index]);
          availablesArrayTo[index].push([i, formatTime(i), !(hourFrom + minHours <= i), index])
        }
      } else {
        for (let i = from; i <= to; i++) {
          if (range === index) {
          availablesArrayFrom[index].push([i, formatTime(i), !(i + minHours <= hourTo), index]);
          availablesArrayTo[index].push([i, formatTime(i), !(hourFrom + minHours <= i), index])
          }
        }
      }
    } else {
      if (index === 0) {
        availablesArrayFrom[index].push([from, formatTime(from), false, index])
        availablesArrayTo[index].push([to, formatTime(to), false, index])
      }
    }
  });

  return { from: availablesArrayFrom, to: availablesArrayTo }
}