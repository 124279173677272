import { isArray } from 'lodash';
import Select from '../customSelect';

export default function updateSelect(element, optionList, timeFrom = 0) {

  let options = optionList.map((hours, index) => {
    if (_.isArray(hours)) {
      return hours.map(item => (
        timeFrom <= item[0]
          ? `<option value="${item[0]}" data-range="${index}"${item[2] ? ' disabled' : ''}>${item[1]}</option>`
          : null
      ))
    } else {
      timeFrom <= item[0]
        ? `<option value="${hours[0]}" data-range="0"${hours[2] ? ' disabled' : ''}>${hours[1]}</option>`
        : null
    }
  });

  element.parent().find('.custom-select__field').remove();
  element.parent().find('.custom-select__dropdown').remove();

  options.unshift("<option value='' disabled></option>");
  element.html(options.flat().join(''));
  new Select(element);
}
