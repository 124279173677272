export function tabs() {
  const tab = document.querySelectorAll('.js-tab');

  for (let i = 0; i < tab.length; i++) {
    tab[i].addEventListener('click', function(e) {
      e.preventDefault();

      let tabData = this.dataset.targetTab;
      let target = document.querySelector('[data-tab="' + tabData + '"]');

      let tabTargetItemElse = document.querySelectorAll(
        '.js-tabs-item:not([data-tab="' + tabData + '"])'
      );
      tabTargetItemElse = [...tabTargetItemElse];

      let tabElse = document.querySelectorAll(
        '.js-tab:not([data-target-tab="' + tabData + '"])'
      );
      tabElse = [...tabElse];

      for (let j = 0; j < tabTargetItemElse.length; j++) {
        tabTargetItemElse[j].classList.remove('is-active');
      }
      for (let j = 0; j < tabElse.length; j++) {
        tabElse[j].classList.remove('is-active');
      }
      this.classList.add('is-active');
      target.classList.add('is-active');
    });
  }
}
