import { isUpperCase } from './isUpperCase.js';

// in case 4 capitalized letters in a row
// it replaces them with lower case
export function forbidAllCaps() {
  if($('.venue-registration').length) {
    $("input[type='text'], textarea").each(function(){
      let input = $(this);

      input.keyup(function() {
        let value = $(this).val();
        let words = value.split(" ");

        $.each(words, function(_i,v){
          if (isUpperCase(v)) {
            let newWord = v.toLowerCase();
            let newValue = input.val().replace(v, newWord)

            input.val(newValue)
          }
        });
      })
    })
  }
}
