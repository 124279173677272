import flatpickr from 'flatpickr';
import moment from 'moment';

export function datePicker() {
  let currentDate = new Date();
  let endDate = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
  let targetInput = $('.js-dates-input');
  let bookedDaysInput = $('.js-booked-dates-input');
  let selectedDates = targetInput.val() ? JSON.parse(targetInput.val()) : [];
  let bookedDays = bookedDaysInput.val() ? JSON.parse(bookedDaysInput.val()) : [];

  flatpickr('.js-datepicker', {
    altInput: true,
    inline: true,
    clickOpens: false,
    closeOnSelect: false,
    ignoredFocusElements: [document.body],
    mode: 'multiple',
    minDate: currentDate,
    maxDate: endDate,
    dateFormat: "Y-m-d",
    defaultDate: selectedDates,
    disable: bookedDays,
    monthSelectorType: 'static',
    onChange: (e) => {getSelectedDates(e)},
    onDayCreate: (dObj, dStr, fp, dayElem) => {
      if (bookedDays.indexOf(moment(dayElem.getAttribute("aria-label")).format('YYYY-MM-DD')) > -1) {
        dayElem.classList.add('booked')
      }
    }
  });

  function formatDate(date) {
    let month, day;
    month = date.getMonth() + 1;
    day = date.getDate();

    return date.getFullYear() + '-' + (month > '9' ? month : '0' + month) + '-' + (day > '9' ? day : '0' + day);
  }

  // generate selected dates array for multiple choice
  function getSelectedDates(e) {
    selectedDates = e.map((date) => {
      return formatDate(date);
    });
    selectedDates.sort();
    targetInput.val(JSON.stringify(selectedDates));
  }
}
