import formatDate from "./formatDate";
import updateSelect from "./updateSelect";
import createAvailableHoursArray from "./createAvailableHoursArray";
import updateFormFields from "./updateFormFields";
import {
  datepickerDataInput,
} from './selectors';
let availablesArray = [];

export const datesArray = [];

export default function getDayAvailableHours(date, selectFromBlock, selectToBlock, type) {
  let requestPath = datepickerDataInput.data('request-path');
  let currentDateStr = datepickerDataInput.data('current-day');
  let currentHour = +datepickerDataInput.data('current-hour');
  let selectedDate = formatDate(date[0]);

  datesArray.length = 0;

  $.ajax({
    url: requestPath,
    method: 'POST',
    data: {date: selectedDate},
    success: function (event) {

      datesArray.push(...event)

      availablesArray = createAvailableHoursArray(datesArray, type);

      let timeFrom;
      if (selectedDate === currentDateStr && currentHour > availablesArray.from[0][0][0]) {
        timeFrom = currentHour + 1
      } else {
        timeFrom = availablesArray.from[0][0][0]
      }

      if (selectFromBlock.length) {
        updateSelect(selectFromBlock, availablesArray.from, timeFrom);
        if (type === 'from') selectFromBlock.parent().find('.custom-select__list li').last().click();
      }
      if (selectToBlock.length) {
        updateSelect(selectToBlock, availablesArray.to, timeFrom);
        if (type === 'to') selectToBlock.parent().find('.custom-select__list li').last().click();
      }

      updateFormFields(type, formatDate(date[0]), timeFrom, datesArray[0][1]);
      $('.js-reset-hours').removeClass('hidden')
    },
    error: function (e, data) {
      console.log('=====', 'e', e);
      console.log('=====', 'data', data);
    }
  });
}
