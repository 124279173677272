export default function formatTime(timeString) {
  let hour = timeString;
  let dayFormat;

  if (+hour < 24) {
    dayFormat = +hour < 12 ? ' a.m.' : ' p.m.';
  } else {
    dayFormat = ' a.m.';
  }
  hour = +hour % 12 || 12;

  return hour + ':00 ' + dayFormat;
}