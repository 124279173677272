export function passwordInput() {
  let inputWrapper = $('.js-form-password');
  let passToggleBtn = $('.js-form-password').find('.js-toggle-password');
  let inputPass = $(inputWrapper).find('input[type="password"]');

  $(inputPass).on('input change', function() {
    let valueLength = this.value.length;
    let toggleBtn = $(this).closest('.js-form-password').find('.js-toggle-password');
    if(valueLength >= 1) {
      $(toggleBtn).show();
    } else {
      $(toggleBtn).hide();
    }
  });

  $(passToggleBtn).on('click', function(e) {
    e.preventDefault();
    let input = $(this).closest('.js-form-password').find('input');
    if (input[0].type === 'password') {
      input[0].type = 'text';
      $(this).text('Hide').removeClass('btn_primary').addClass('btn_secondary');
    } else {
      input[0].type = 'password';
      $(this).text('Show').removeClass('btn_secondary').addClass('btn_primary');
    }
  });
}
