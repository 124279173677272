export const validateEmptyMessage = () => {
  let submit = document.querySelector('.js-message-submit');
  let errorBlock = $('.js-counter-error');

  if (submit) {
    submit.onclick = e => {
      if (document.querySelector('.js-message-input').value.length < 1 && document.querySelector('.js-file-upload-result').value.length < 1) {
        $('.counter__error').addClass('has-error');
        errorBlock.html('Message can not be blank.');
        e.preventDefault()
      }
    }
  }
}
