import { numberWithCommas } from './numberWithCommas.js';

export function textareaCounter() {
  let counters = $('.js-textarea-counter');
  if (counters.length > 0) {
    let textarea = counters.closest('.js-textarea').find('textarea, input');
    let textareaCountAmount = +textarea.data('max-count');
    $(counters).text(numberWithCommas((textareaCountAmount - $(textarea).val().length)));
    $(textarea).on('input', function() {
      $(counters).text(numberWithCommas((textareaCountAmount - $(this).val().length)));
    });
  }
}
