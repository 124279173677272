export function mainSearch() {
  $('#event_list a').on('click', function() {
    const text = $(this).text();
    const id = $(this).data('id');

    $('#search_event_text').val(text);
    $('#search_event_id').val(id);

    // close dropdown
    $(document).trigger('click');

    return false;
  });
}
