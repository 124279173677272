export const currentDate = new Date();
export const endDate = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
export const datepickerRow = $('.booking-request__row');

export const datepickerDataInput = $('input.datepicker-data');
export const dataDisabledDates = datepickerDataInput.data('disabled-dates') ? datepickerDataInput.data('disabled-dates') : [new Date()];
export const dataDaysWithMultipleRanges = datepickerDataInput.data('multiple-ranges-days') ? datepickerDataInput.data('multiple-ranges-days') : [];
export const dataDisabledDaysOfWeek = datepickerDataInput.data('disabled-days') ? datepickerDataInput.data('disabled-days') : [];

// vars for sending data to backend
  export const bookingForm = $('.js-booking-form');
  export const bookingFormStartDate = bookingForm.find('.js-form-start-date');
  export const bookingFormEndDate = bookingForm.find('.js-form-end-date');
  export const bookingFormFromTime = bookingForm.find('.js-form-from-time');
  export const bookingFormToTime = bookingForm.find('.js-form-to-time');
  export const bookingFormType = bookingForm.find('.js-form-type');