export function onlyNumbers() {
  $('input[type="number"]').each(function () {
    let $this = $(this);

    $this.on('input', function (e) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    });

    $this.on('keypress', function () {
      return event.charCode >= 48 && event.charCode <= 57
    });
  })
}