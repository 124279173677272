export function infoModal() {
  let infoBlock = $('.js-info-modal');
  let closeInfoModal = $('.js-close-info-modal');

  closeInfoModal.on('click', function() {
    $(this).closest('.js-info-modal').hide();
  });

  infoBlock.each(function() {
    const $this = $(this);

    if ($this.hasClass('alert') || $this.hasClass('notice' )) {
      const hideBlock = () => {
        $this.hide(400);
      };
      setTimeout(hideBlock, 7000);
    }
  });
}
