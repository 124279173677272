import PubSub from 'pubsub-js';
import { BODY, ISFIXED, ISHIDDEN } from '../_constants';
import { addBodyHidden, removeBodyHidden } from './bodyHiddenPosition.js';

export function popups() {

  let bodyHiddenStatus = 0;
  $('.js-popup-link').on('click', function(e) {
    if (!$(this).hasClass('phone-verification')) {
      e.preventDefault();
    }

    let $this = $(this),
      attr = $this.attr('data-popup');
    let target = $(`.js-popup[data-popup="${attr}"]`);

    if ($this.hasClass('js-popup-gallery-link')) {
      PubSub.publish('open-popup-gallery', $this);
    }

    $('.js-popup').fadeOut(200);
    target.fadeIn(300);

    if(!target.hasClass('is-scrollable')) {
      addBodyHidden();
    }
  });

  $('.js-popup-overlay, .js-close-popup').on('click', function(e) {
    e.stopPropagation();

    let parent = $(this).parents('.js-popup');
    parent.fadeOut(200);

    let videosFrame = $('.js-video-in-popup').closest('iframe');
    if(videosFrame !== null && videosFrame !== undefined) {
      $(videosFrame).remove();
    }
    if (parent.attr('data-popup') === 'popup-gallery') {
      PubSub.publish('close-popup-gallery');
    }

    if (bodyHiddenStatus === 1) {
      bodyHiddenStatus = 0;
    } else {
      BODY.removeClass(ISHIDDEN);
      if(!parent.hasClass('is-scrollable')) {
        removeBodyHidden();
      }
      $('.js-header').removeClass(ISFIXED);
    }
  });

  $('.js-popup-content').on('click', function(e) {
    e.stopPropagation();
  });

}
