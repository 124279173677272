export function sharePopup() {
  let socialBtn = $('.js-open-social-popup');

  if (socialBtn.length) {
    socialBtn.each(function () {
      let $this = $(this);
      $this.on('click', function (e) {
        e.preventDefault();
        window.open($this.attr("href"), '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
      });
    });
  }
}
