import { ISOPEN } from '../_constants';

export function inputDropdown() {
  let input = $('.js-input-dropdown');
  let allInputDropdowns = $('.js-dropdown');

  $(input).click(function(e) {
    let dropdown = $(this).find('.js-dropdown');
    e.stopPropagation();

    if(!$(dropdown).hasClass(ISOPEN)) {
      $(allInputDropdowns).removeClass(ISOPEN);
      $(allInputDropdowns).slideUp(300);
      $(dropdown).addClass(ISOPEN);
      $(dropdown).slideDown(300);
    } else {
      if ($(e.target).closest('.js-input-dropdown')) {
        e.stopPropagation();
        return;
      }
      $(dropdown).removeClass(ISOPEN);
      $(dropdown).slideUp(300);
    }
  });


  $(document).on('click', function(e) {
    let dropdown = $('.js-dropdown');
    if(!$(e.target).closest('.js-input-dropdown').length) {
      if($(dropdown).hasClass(ISOPEN)) {
        $(dropdown).removeClass(ISOPEN);
        $(dropdown).slideUp(300);
      }
    }
  });

}
