import { ISOPEN, BODY, ISHIDDEN } from '../_constants';
import { addBodyHidden, removeBodyHidden } from './bodyHiddenPosition.js';

export function clickFunctions() {
  let burger = $('.js-burger');
  let nav = $('.js-nav');
  let sidebar = $('.js-sidebar');

  $(burger).on('click', function() {
    if($(this).hasClass(ISOPEN)) {
      $(this).removeClass(ISOPEN);
      $(nav).removeClass(ISOPEN);
      $(sidebar).removeClass(ISOPEN);
      BODY.removeClass(ISHIDDEN);
      $(nav).slideUp(300);
      removeBodyHidden();
    } else {
      $(this).addClass(ISOPEN);
      $(sidebar).addClass(ISOPEN);
      $(nav).addClass(ISOPEN);
      $(nav).slideDown(300);
      addBodyHidden();
    }
  });

  let scrollTimeout;
  const throttle = 500;
  $(window).on('resize', function() {
    if (!scrollTimeout) {
      scrollTimeout = setTimeout(function() {
        if(burger.hasClass(ISOPEN)) {
          if (window.matchMedia('(min-width: 767px)').matches) {
            BODY.removeClass(ISHIDDEN);
          } else {
            BODY.addClass(ISHIDDEN);
          }
        }
        scrollTimeout = null;
      }, throttle);
    }
  });

}
