import { validateDonation } from './validateDonation.js'

export function validateBenefiting() {
  let validationSource = $('.js-validation-source');

  if ($('.js-benefiting-organization').length) {
    let validationTarget = $('.js-validate-benefiting');
    let userId = +validationSource.data('user-id');
    let select = $('.js-select');
    let inputTarget = $('.js-validation-target');

    select.on('custom-select:change', function () {
      if (+select.find('option:selected').val() === userId) {
        validationTarget.addClass('hidden');
        inputTarget.val(inputTarget.attr('min'));
      } else {
        validateDonation();
        validationTarget.removeClass('hidden');
      }
    });
  }
}