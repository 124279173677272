export function reinitVideoPopup() {

  var target = document.querySelectorAll('.video');
  target = [...target];

  var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      // console.log(mutation);
      let ifvideoPopupChanged = mutation.target.children[0].className === 'js-video-in-popup';

      if(ifvideoPopupChanged && mutation.removedNodes.length) {
        let ifNotIframe = $(mutation.removedNodes[0]).prop('tagName') === 'IFRAME';
        if(!ifNotIframe) {
          let removedNode = mutation.removedNodes[0];
          $(mutation.target).append(removedNode);          
          $(mutation.target).closest('iframe').remove();
        }
      }
    });    
  });
 
  // configuration
  var config = { attributes: true, childList: true, characterData: true };
 
  for(let i=0; i<target.length; i++) {
    observer.observe(target[i], config);    
  }
 
// to stop observe
// observer.disconnect();
}
