import {
  bookingFormStartDate,
  bookingFormEndDate,
  bookingFormFromTime,
  bookingFormToTime,
  bookingFormType,
  bookingForm
} from './selectors';

// generate selected dates array for multiple choice
export default function updateFormFields(type, date, from, to) {
  let calendarType = 'multiple';
  if (type === 'from-to') {
    bookingFormStartDate.val(date);
    bookingFormEndDate.val(date);
    calendarType = 'single';
  } else if (type === 'from') {
    bookingFormStartDate.val(date);
  } else if (type === 'to') {
    bookingFormEndDate.val(date);
  }
  bookingFormStartDate.trigger('hidden-input-change');
  bookingFormEndDate.trigger('hidden-input-change');
  // bookingFormFromTime.val(from).trigger('hidden-input-change');
  // bookingFormToTime.val(to).trigger('hidden-input-change');
  bookingFormType.val(calendarType).trigger('hidden-input-change');
  bookingForm.data('type', type);
}