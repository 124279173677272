import IMask from 'imask';
import {env} from "../envVariables.js.erb";

export const mask = () => {
  const maskEl = document.querySelectorAll('.js-mask-phone');
  const mask = env.TWILIO_PHONE_MASK;
  const submitBtn = $('.js-profile-submit');
  const form = $('.js-profile-form');

  const validateNumber = (number = '', errorEl) => {
    console.log('=====', 'validateNumber')
    if (number.length > 0 && number.length < 10) {
      errorEl.text(`The number is invalid. Number must comply with the following mask \"${mask}\"`);
      return false
    } else {
      errorEl.text('');
      return true
    }
  };

  if (maskEl.length) {
    const maskOptions = {
      mask
    };

    maskEl.forEach(item => {
      const el = $(item);
      const mask = IMask(item, maskOptions);
      const errorEl = el.closest('.field').find('.js-phone-error');

      submitBtn.on('click', () => {
        const unmaskedValue = mask._unmaskedValue;
        el.data('unmasked-value', unmaskedValue);
        validateNumber(unmaskedValue, errorEl)
      })
    });

    submitBtn.on('click', e => {
      e.preventDefault();

      const validationArray = [];

      maskEl.forEach(item => {
        const el = $(item);
        const unmaskedValue = el.data('unmasked-value') || '';
        const errorEl = el.closest('.field').find('.js-phone-error');
        const isValid = validateNumber(unmaskedValue, errorEl);
        if (!isValid) validationArray.push(0);
        el.val(unmaskedValue);
      });

      if (validationArray.length === 0) form.submit();
    });
  }
};
