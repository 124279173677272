import { ZoomControl } from './mapZoomBtns.js';
import { ISOPEN } from '../_constants';
import isTouchDevice from '../lib/detectTouch.js';
import { env } from '../envVariables.js.erb';

class Map {
  constructor(config, mainMap) {
    this.elem = $(config);
    this.mapMain = mainMap;
    this._initMap()
  }

  _initMap() {
    let self = this;

    if(this.mapMain) {
      self._searchMap();
    } else {
      let mapWrapper = self.elem,
        mapInitBlock = mapWrapper.find('.js-map-init'),
        zoomValue = parseInt(mapInitBlock.attr('data-zoom')),
        coords = mapInitBlock.attr('coords').split(','),
        latitude = parseFloat(coords[0]),
        longtitude = parseFloat(coords[1]),
        iconSrc = mapWrapper.find('.js-active-pin').attr('src'),
        data = $('#listings_data').data('listing');

      let icon = {
        url: iconSrc, // url
        scaledSize: new google.maps.Size(40, 40), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(20, 40) // anchor
      };

      let map = new google.maps.Map(mapInitBlock[0], {
        zoom: zoomValue,
        center: {lat: latitude, lng: longtitude},
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false
      });

      let marker = new google.maps.Marker({
        position: { lat: latitude, lng: longtitude },
        map: map,
        icon: icon
      });

      let zoomInButton = $(mapWrapper).find('.js-map-zoomin');
      let zoomOutButton = $(mapWrapper).find('.js-map-zoomout');

      ZoomControl(map, zoomInButton[0], zoomOutButton[0]);
    }
  }

  _searchMap() {
    let self = this;
    let mainMapSearch = $('#js-main-map');
    let mapWrapper = $(mainMapSearch).closest('.js-map');
    let zoomValue = parseInt($(mainMapSearch).attr('data-zoom'));
    let data = $('#listings_data').data('listing');
    let map = new google.maps.Map(document.getElementById('js-main-map'), {
      zoom: zoomValue,
      center: {lat: data[0].latitude, lng: data[0].longitude},
      mapTypeControl: false,
      zoomControl: false,
      fullscreenControl: false
      // disableDefaultUI: true
    });

    let zoomInButton = $(mapWrapper).find('.js-map-zoomin');
    let zoomOutButton = $(mapWrapper).find('.js-map-zoomout');

    ZoomControl(map, zoomInButton[0], zoomOutButton[0]);

    self._fullscreen(mapWrapper);

    let mapData = mainMapSearch[0].getAttribute('data-json');

    self._setMarkers(data, map, mapWrapper);
  }

  _setMarkers(items, map, mapWrapper) {
    let self = this;
    let mainMapBlock = mapWrapper;

    let inactivePin = $(mainMapBlock).find('.js-inactive-pin');
    const INACTIVEPIN = $(inactivePin).attr('src');

    let activePin = $(mainMapBlock).find('.js-active-pin');
    let ACTIVEPIN = $(activePin).attr('src');

    let closePopupBtn = $(mainMapBlock).find('.js-close-map-popup');
    let closePopupBtnSrc = $(closePopupBtn).attr('src');

    let bounds = new google.maps.LatLngBounds();

    if (INACTIVEPIN) {
      var icon = {
        url: INACTIVEPIN
      };
    }

    let InfoBox = require('../lib/infoBoxMap.js');
    if (window.matchMedia('(max-width: 599px)').matches) {
      var infoBoxMobPosXAxis = -150;
    } else {
      var infoBoxMobPosXAxis = 0;
    }

    var myOptions = {
      content: ''
      , disableAutoPan: false
      , maxWidth: 0
      , pixelOffset: new google.maps.Size(-150, infoBoxMobPosXAxis)
      , zIndex: null
      , boxStyle: {
        backgroundColor: '#fff'
      }
      , closeBoxMargin: ''
      , closeBoxURL: closePopupBtnSrc
      , infoBoxClearance: new google.maps.Size(1, 1)
      , isHidden: false
      , pane: 'floatPane'
      , enableEventPropagation: false
    };
    let infoBoxCustom = new InfoBox(myOptions);
    let markerArray = [];

    for (let i = 0; i < items.length; i++) {
      let latitude = parseFloat(items[i].offset_latitude);
      let longtitude = parseFloat(items[i].offset_longitude);

      let marker = new google.maps.Marker({
        position: { lat: latitude, lng: longtitude },
        map: map,
        icon: icon,
        id: i
      });

      bounds.extend(marker.position);

      markerArray.push(marker);

      var clicked;

      let residenceCards = document.querySelectorAll('.js-residence-card-map');
      residenceCards = [...residenceCards];

      for (let i = 0; i < residenceCards.length; i++) {
        residenceCards[i].addEventListener('mouseover', function () {
          let infoOpen = infoBoxCustom.content_.length;
          let cardId = parseInt(residenceCards[i].getAttribute('data-card-id'), 10);
          if (cardId === marker.id && infoOpen === 0) {
            marker.setIcon(ACTIVEPIN);
          }
        });

        residenceCards[i].addEventListener('mouseleave', function () {
          let cardId = parseInt(residenceCards[i].getAttribute('data-card-id'), 10);
          let infoOpen = infoBoxCustom.content_.length;
          if (cardId === marker.id && infoOpen === 0) {
            for (let j = 0; j < markerArray.length; j++) {
              markerArray[j].setIcon(INACTIVEPIN);
            }
          }
        });
      }

      google.maps.event.addListener(marker, 'mouseover', function () {
        if (!clicked) {
          marker.setIcon(ACTIVEPIN);
          if (residenceCards[i]) {
            let cardId = parseInt(residenceCards[i].getAttribute('data-card-id'), 10);
            if (cardId === marker.id) {
              $(residenceCards[i]).addClass('is-marker-hovered');
            } else {
              $(residenceCards[i]).removeClass('is-marker-hovered');
            }
          }
        }
      });

      google.maps.event.addListener(marker, 'mouseout', function () {
        if (!clicked) {
          marker.setIcon(INACTIVEPIN);
          if (residenceCards[i]) {
            $(residenceCards[i]).removeClass('is-marker-hovered');
          }
        }
      });

      google.maps.event.addListener(marker, 'click', function () {
        clicked = true;
        if (isTouchDevice()) {
          for (let h = 0; h < markerArray.length; h++) {
            if (residenceCards[h]) {
              $(residenceCards[h]).removeClass('is-marker-hovered');
            }
            $(residenceCards[i]).addClass('is-marker-hovered');
          }
        }

        function printFilledRate(vPoints) {
          let str = '';
          for (let k = 0; k < vPoints; k++) {
            str += '<i class="rating__star is-full"><svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg></i>';
          }
          return str;
        }

        function printEmptyRate(vPoints) {
          let str = '';
          for (let k = 0; k < 5 - vPoints; k++) {
            str += '<i class="rating__star"><svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg></i>';
          }
          return str;
        }

        var contentBox =
          '<a href="'+ items[i].self_url +'" target="_blank" class="residence-card__inner">'+
          '<div class="residence-card__images">'+
          '<div class="residence-card__image">'+
          '<img src="'+ items[i].first_thumb_url +'" alt=""/>'+
          '</div>'+
          '</div>'+
          '<div class="residence-card__description">'+
          '<h4 class="residence-card__title">'+ items[i].venue_title +'</h4>'+
          '<div class="residence-card__subtitle">'+ items[i].short_venue_address +'</div>'+
          '<div class="residence-card__bottom">'+
          '<div class="residence-card__price"> $'+ (items[i].total_full_hourly_rate.fractional / 100).toFixed(2) +'/hour</div>'+
          '<div class="residence-card__rating">'+
          '<div class="rating">' + printFilledRate(items[i].rating) +
          printEmptyRate(items[i].rating) +
          '</div>'+
          '</div>'+
          '</div>'+
          '</div>'+
          '</a>';
        if (infoBoxCustom) {
          infoBoxCustom.close();
          for (let j = 0; j < markerArray.length; j++) {
            markerArray[j].setIcon(INACTIVEPIN);
          }
        }
        infoBoxCustom.setContent(contentBox);
        infoBoxCustom.open(map, marker);
        map.panTo(marker.getPosition());
        marker.setIcon(ACTIVEPIN);
      });

      google.maps.event.addListener(infoBoxCustom,
        'closeclick',
        function () {
          clicked = false;
          for (let j = 0; j < markerArray.length; j++) {
            markerArray[j].setIcon(INACTIVEPIN);
          }
          if (residenceCards[i]) {
            $(residenceCards[i]).removeClass('is-marker-hovered');
          }
          infoBoxCustom.setContent('');
        });
    }

    map.fitBounds(bounds);
  }

  _fullscreen(mapWrapper) {
    let mapFullscreen = $(mapWrapper).find('.js-map-fullscreen');
    let mapFullscreenCloseBtn = $(mapWrapper).find('.js-map-fullscreen-close');
    let mapMainWrapper = $(mapWrapper).closest('.js-map-main-wrapper');

    let mapFullscreenWrapper = $(mapMainWrapper).find('.js-map-fullscreen-wrapper');
    let mapFullscreenExpandBtn = $(mapMainWrapper).find('.js-map-fullscreen');
    let mapFullscreenClose = $(mapMainWrapper).find('.js-map-fullscreen-close');

    function toggleMapExpanding(btn, el) {
      if ($(mapFullscreenWrapper).hasClass(ISOPEN)) {
        $(mapFullscreenWrapper).removeClass(ISOPEN);
        $(btn).removeClass(ISOPEN);
        $(el).removeClass(ISOPEN);
      } else {
        $(mapFullscreenWrapper).addClass(ISOPEN);
        $(btn).addClass(ISOPEN);
        $(el).addClass(ISOPEN);
      }
    }

    $(mapFullscreen).on('click', function() {
      toggleMapExpanding(mapFullscreenClose, this);
    });

    $(mapFullscreenCloseBtn).on('click', function() {
      toggleMapExpanding(mapFullscreenExpandBtn, this);
    });
  }

  _getUrl(jsonName) {
    return window.location.protocol + '//' + window.location.host + `/data/${jsonName}.json`;
  }
}

$.fn.maps = function(main) {
  this.each((i, el) => new Map($(el), main));
  return this;
};

/*** search map ***/
$('#js-main-map').maps(true);
/*****************/

$('.js-map-common').maps();
