let chooseBlock = $('.js-check-select-values');
let bookingForm = $('.js-booking-form');
let bookingFormStartDate = bookingForm.find('.js-form-start-date');
let bookingFormEndDate = bookingForm.find('.js-form-end-date');
let bookingFormFromTime = bookingForm.find('.js-form-from-time');
let bookingFormToTime = bookingForm.find('.js-form-to-time');
let bookingFormType = bookingForm.find('.js-form-type');

let bookingRowFromTo = $('.booking-request__row[data-type="row-from-to"]');
let bookingRowFrom = $('.booking-request__row[data-type="row-from"]');
let bookingRowTo = $('.booking-request__row[data-type="row-to"]');

export function datePickerBookingToggle() {
  $('.js-toggle-time-selects').on('click', function () {
    let $this = $(this);
    let startDate, endDate, fromTime, toTime;
    let type;
    chooseBlock.toggleClass('is-active');

    if ($this.data('select-time-target') === 'multiple') {
      startDate = bookingRowFrom.find('.js-datepicker-booking').val();
      endDate = bookingRowTo.find('.js-datepicker-booking').val();
      fromTime = bookingRowFrom.find('.js-select-start-time option[selected="selected"]').val();
      toTime = bookingRowTo.find('.js-select-end-time option[selected="selected"]').val();
      type = 'multiple';
    } else {
      startDate = bookingRowFromTo.find('.js-datepicker-booking').val();
      endDate = startDate;
      fromTime = bookingRowFromTo.find('.js-select-start-time option[selected="selected"]').val();
      toTime = bookingRowFromTo.find('.js-select-end-time option[selected="selected"]').val();
      type = 'single';
    }

    bookingFormStartDate.val(startDate ? startDate : '').trigger('hidden-input-change');
    bookingFormEndDate.val(endDate ? endDate : '').trigger('hidden-input-change');
    bookingFormFromTime.val(fromTime ? fromTime : '').trigger('hidden-input-change');
    bookingFormToTime.val(toTime ? toTime : '').trigger('hidden-input-change');
    bookingFormType.val(type).trigger('hidden-input-change');
  });
}