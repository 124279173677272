export function validateDonation() {
  let donationEl = $('.js-validate_donation');

  let minDonationValue = +donationEl.data('min-donation');
  let nextBtn = $('.js-next-button');
  let inputEl = $('.js-validation-target');

  nextBtn.on('click', function (e) {
    e.preventDefault();

    if (inputEl.val() < minDonationValue) {
      let errorMessage = `Must be greater than or equal to ${minDonationValue}`;

      if ($('.js-donation-error').length) {
        $('.js-donation-error').text(errorMessage);
      } else {
        donationEl.append(`<div class="field__error-message js-donation-error">${errorMessage}</div>`);
      }
      donationEl.addClass('is-error')
    } else {
      $('.js-donation-error').remove();
      donationEl.removeClass('is-error');
      $('form').submit();
    }
  });
}
