import {env} from '../envVariables.js.erb';
import {validateBooking} from './validateBooking.js';

export function initStripe() {
  if (document.querySelector('.js-stripe-payment-form')) {
    let stripe = Stripe(env.STRIPE_PUBLISHABLE_KEY);
    let elements = stripe.elements();
    let displayErrorEl = document.querySelector('.payment__error-message');
    let paymentForm = document.getElementById('payment-form');
    let cardholderInputWrap = document.querySelector('.js-cardholder-name');
    let cardholderInput = document.getElementById('cardholder-name');
    let submitBtn = document.querySelector('.js-stripe-submit');
    let validationResult;

    // Style the payment element
    let style = {
      base: {
        fontSize: "16px"
      }
    };

    let classes = {
      invalid: 'is-error',
    };

    // Create an instance of stripe Elements
    let cardNumber = elements.create('cardNumber', {style: style, classes: classes});
    cardNumber.mount('#stripe-card-number');

    let cardCvc = elements.create('cardCvc', {style: style, classes: classes});
    cardCvc.mount('#stripe-cvc');

    let cardExpiry = elements.create('cardExpiry', {style: style, classes: classes});
    cardExpiry.mount('#stripe-exp-date');

    handleErrors(cardNumber);
    handleErrors(cardCvc);
    handleErrors(cardExpiry);

    // Listen for errors
    function handleErrors(component) {
      component.addEventListener('change', function (event) {

        if (event.error) {
          displayErrorEl.textContent = event.error.message;
          displayErrorEl.style.display = 'block';
        } else {
          displayErrorEl.textContent = '';
          displayErrorEl.style.display = 'none';
        }
      });
    }

    function validateCardholderInput() {
      if (cardholderInput.value === '') {
        cardholderInputWrap.classList.add('is-error');
        displayErrorEl.textContent = 'Please enter Cardholder Name';
        displayErrorEl.style.display = 'block';
      } else {
        cardholderInputWrap.classList.remove('is-error');
        displayErrorEl.textContent = '';
        displayErrorEl.style.display = 'none';
      }
    }

    cardholderInput.addEventListener('input', () => {
      validateCardholderInput();
    });

    // Create a token
    submitBtn.addEventListener('click', function (event) {
      event.preventDefault();

      validationResult = validateBooking();

      validateCardholderInput();
      if (cardholderInput.value === '') {
        return;
      }

      var submit_btn = document.querySelector('.js-stripe-submit');
      var cardDetails = {name: cardholderInput.value};

      if (validationResult) {
        stripe.createToken(cardNumber, cardDetails).then(function (result) {
          if (result.error) {
            displayErrorEl.textContent = result.error.message;
            displayErrorEl.style.display = 'block';
            submit_btn.classList.remove("disabled");
          } else {
            // Reset errors
            displayErrorEl.textContent = '';
            displayErrorEl.style.display = 'none';
            submit_btn.classList.add("is-disabled");
            // Send the token to the server
            stripeTokenHandler(result.token);
          }
        });
      }
    });

    // Submit the token
    function stripeTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      paymentForm.appendChild(hiddenInput);

      // Submit the form
      paymentForm.submit();
    }
  }
}
