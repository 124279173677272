
import debounce from 'debounce';
import { fixedHeader } from './fixedHeader.js';

export function fixedHeaderOnScroll() {
  window.onscroll = debounce(fixedHeader, 25);
}

// var scrollTimeout;
// var throttle = 50;

// $(window).on('scroll', function() {
//   if (!scrollTimeout) {
//     scrollTimeout = setTimeout(function() {
//       fixedHeader();
//       scrollTimeout = null;
//     }, throttle);
//   }
// });
