export function loading() {
  if ($('[data-loading="true"]').length) {
    let loadingSvg = '<svg xmlns="http://www.w3.org/2000/svg" id="logo" data-name="logo" viewBox="29 0 56 32">\n' +
      '  <defs><style>.logo-subname{fill:#1aa99e}.logo-name{fill:#1b75bc;}</style></defs>\n' +
      '  <g transform="translate(38 2.8)">\n' +
      '    <circle cx="0" cy="0" r="2.9" class="logo-subname" transform="scale(0.977012 0.977012)">\n' +
      '      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0 1;0.3 0 0 1" values="0;1;0" keyTimes="0;0.95;1" dur="2s" repeatCount="indefinite"></animateTransform>\n' +
      '    </circle>\n' +
      '  </g>\n' +
      '  <g transform="translate(47 2.8)">\n' +
      '    <circle cx="0" cy="0" r="2.9" class="logo-subname" transform="scale(0.977012 0.977012)">\n' +
      '      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0 1;0.3 0 0 1" values="0;1;0" keyTimes="0;0.95;1" dur="2s" repeatCount="indefinite"></animateTransform>\n' +
      '    </circle>\n' +
      '  </g>\n' +
      '  <g transform="translate(66.6 2.8)">\n' +
      '    <circle cx="0" cy="0" r="2.9" class="logo-subname" transform="scale(0.977012 0.977012)">\n' +
      '      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0 1;0.3 0 0 1" values="0;1;0" keyTimes="0;0.95;1" dur="2s" repeatCount="indefinite"></animateTransform>\n' +
      '    </circle>\n' +
      '  </g>\n' +
      '  <g transform="translate(76 2.8)">\n' +
      '    <circle cx="0" cy="0" r="2.9" class="logo-subname" transform="scale(0.977012 0.977012)">\n' +
      '      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0 1;0.3 0 0 1" values="0;1;0" keyTimes="0;0.95;1" dur="2s" repeatCount="indefinite"></animateTransform>\n' +
      '    </circle>\n' +
      '  </g>\n' +
      '  <path class="logo-name" d="M51.16,11c0-1.63,2.77-1.63,2.77,0V21.94c0,6.38-5.51,9.61-10.9,9.61s-10.89-3.23-10.89-9.61V11c0-1.47,2.77-1.47,2.77,0v10.9c0,9.61,16.25,9.61,16.25,0Z" transform="translate(-0.59 -0.82)"/>\n' +
      '  <path class="logo-name" d="M43,32.7c-6,0-12-3.69-12-10.76V11a2.35,2.35,0,0,1,2.53-2.25A2.37,2.37,0,0,1,36.06,11v10.9c0,4.16,3.61,6,7,6s7-1.89,7-6V11a2.55,2.55,0,0,1,5.08,0V21.94C55.08,29,49,32.7,43,32.7ZM33.29,11.1V21.94c0,5.81,5.05,8.45,9.74,8.45s9.74-2.64,9.74-8.45V11a.52.52,0,0,0-.47,0h0v10.9c0,5.49-4.67,8.36-9.28,8.36s-9.28-2.87-9.28-8.36V11.11A.72.72,0,0,0,33.29,11.1Z" transform="translate(-0.59 -0.82)"/>\n' +
      '  <path class="logo-name" d="M80.11,11c0-1.63,2.77-1.63,2.77,0V21.94c0,6.38-5.5,9.61-10.9,9.61s-10.89-3.23-10.89-9.61V11c0-1.47,2.77-1.47,2.77,0v10.9c0,9.61,16.25,9.61,16.25,0Z" transform="translate(-0.59 -0.82)"/>\n' +
      '  <path class="logo-name" d="M72,32.7c-6,0-12.05-3.69-12.05-10.76V11A2.56,2.56,0,0,1,65,11v10.9c0,4.16,3.62,6,7,6s7-1.89,7-6V11A2.42,2.42,0,0,1,81.5,8.66,2.39,2.39,0,0,1,84,11V21.94C84,29,78,32.7,72,32.7ZM62.24,11.1V21.94c0,5.81,5.05,8.45,9.74,8.45s9.74-2.64,9.74-8.45V11a.52.52,0,0,0-.47,0h0v10.9c0,5.49-4.67,8.36-9.28,8.36s-9.28-2.87-9.28-8.36V11.11A.72.72,0,0,0,62.24,11.1Z" transform="translate(-0.59 -0.82)"/>\n' +
      '</svg>';

    $('[data-loading="true"]').on('submit', function () {
      $('.out').append(`<div class="loading__wrapper"><div class="loading__img">${loadingSvg}</div></div>`)
    });
  }
}
