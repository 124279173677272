import flatpickr from 'flatpickr';

import formatDate from './formatDate';
import getDayAvailableHours from './getDayAvailableHours';
import createAvailableHoursArray from './createAvailableHoursArray';
import updateSelect from './updateSelect';
import { datesArray } from './getDayAvailableHours';

import {
  bookingFormStartDate,
  bookingFormEndDate,
  bookingFormType,
  bookingFormFromTime,
  bookingFormToTime,
  currentDate,
  endDate,
  datepickerRow,
  dataDisabledDates,
  dataDaysWithMultipleRanges,
  dataDisabledDaysOfWeek,
  datepickerDataInput
} from './selectors';

export function datePickerBooking() {
  function updateHoursInputs(select, targetInput) {
    select.on('custom-select:change', function () {
      let selectedValue = $(this).find('option[selected="selected"]').val();
      targetInput.val(selectedValue).trigger('hidden-input-change');
    });
  }

  updateHoursInputs($('.js-select-start-time'), bookingFormFromTime);
  updateHoursInputs($('.js-select-end-time'), bookingFormToTime);

// START TODO needs to be refactored
  let selectsToUpdate = $('.booking-request__row[data-type="row-from-to"]');
  let fromSelect = selectsToUpdate.find('.js-select-start-time');
  let toSelect = selectsToUpdate.find('.js-select-end-time');
  let currentSelectedDate = '';

  fromSelect.on('custom-select:change', function () {
    let $this = $(this);
    let to = +$this.children().last().val();

    if (+$this.find('option:selected').val().length) {
      let availablesArray = createAvailableHoursArray(datesArray, 'from-to', +$this.find('option:selected').val(), to, +$this.find('option:selected').data('range'));
      updateSelect(toSelect, availablesArray.to);

      let optionToBeSelected = toSelect.parent().find(`.custom-select__list li[data-value="${+bookingFormToTime.val()}"]`);

      toSelect.parent().find('.custom-select__list li').removeClass('is-active');
      optionToBeSelected.addClass('is-active');
      toSelect.parent().find('.custom-select__text').text(optionToBeSelected.text());
      toSelect.children().removeAttr('selected');
      toSelect.find(`option[value="${+bookingFormToTime.val()}"]`).attr('selected', true);
    }
  });

  toSelect.on('custom-select:change', function () {
    let $this = $(this);
    let from = +$this.children().first().val();

    if (+$this.find('option:selected').val().length) {
      let availablesArray = createAvailableHoursArray(datesArray, 'from-to', from, +$this.find('option:selected').val(), +$this.find('option:selected').data('range'));
      updateSelect(fromSelect, availablesArray.from);

      let optionToBeSelected = fromSelect.parent().find(`.custom-select__list li[data-value="${+bookingFormFromTime.val()}"]`);

      fromSelect.parent().find('.custom-select__list li').removeClass('is-active');
      optionToBeSelected.addClass('is-active');
      fromSelect.parent().find('.custom-select__text').text(optionToBeSelected.text());
      fromSelect.children().removeAttr('selected');
      fromSelect.find(`option[value="${+bookingFormToTime.val()}"]`).attr('selected', true);
    }
  });

// END TODO needs to be refactored

  let datepickers = [];

// booking card datepicker
  datepickerRow.each(function () {
    let $this = $(this);
    let bookingCard = this.querySelector('.js-datepicker-booking');
    let selectFrom = $this.find('.js-select-start-time');
    let selectTo = $this.find('.js-select-end-time');
    let datepickerType = bookingCard.dataset.type;

    datepickers[datepickerType] = initDatepicker(bookingCard, selectFrom, selectTo);
  });

  $(window).scroll(function () {
    Object.keys(datepickers).forEach(item => {
      datepickers[item].close();
    });
  });

  function initDatepicker(datepickerTarget, selectFrom, selectTo) {
    let datepickerType = datepickerTarget.dataset.type;
    let isTodayAvailable = datepickerDataInput.data('current-day-available');
    let currentDay = datepickerDataInput.data('current-day');
    let flatpickrItem = flatpickr(datepickerTarget, {
      mode: 'single',
      disable: [
        function (date) {
          return (dataDisabledDates.indexOf(formatDate(date)) !== -1 || dataDisabledDaysOfWeek.indexOf(date.getDay()) !== -1)
              ||
            (datepickerType !== 'from-to' && dataDaysWithMultipleRanges.indexOf(date.getDay()) !== -1)
              ||
            (isTodayAvailable === false && currentDay === formatDate(date))
        }
      ],
      minDate: currentDate,
      maxDate: endDate,
      format: 'd-m-Y',
      altFormat: 'm/d/Y',
      altInput: true,
      onOpen: function (selectedDates, dateStr, instance) {
        $(instance.calendarContainer).addClass('flatpickr-booking');
      },
      onChange: (date) => {
        if (date.length) {
          updateDependentDatepicker(date, selectFrom, selectTo, datepickerType);
          $('.js-not-available').css('display', 'none');
          $('.js-reset-hours').on('click', () => {
            updateDependentDatepicker(date, selectFrom, selectTo, datepickerType);
            bookingFormStartDate.val('').trigger('hidden-input-change');
            bookingFormEndDate.val('').trigger('hidden-input-change');
            bookingFormFromTime.val('').trigger('hidden-input-change');
            bookingFormToTime.val('').trigger('hidden-input-change');
            bookingFormType.val('').trigger('hidden-input-change');
          })
        }
      }
    });
    return flatpickrItem;
  }

  function updateDependentDatepicker(date, selectFrom, selectTo, datepickerType) {
    if (datepickerType === 'from') {
      datepickers['to'].set('minDate', new Date(date));
    } else if (datepickerType === 'to') {
      datepickers['from'].set('maxDate', new Date(date));
    }

    getDayAvailableHours(date, selectFrom, selectTo, datepickerType);
  }
}
