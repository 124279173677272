export function benefitingShowMission() {
  let missionEl = $('.js-benefiting-mission');

  if (missionEl.length) {
    let missions = missionEl.data('missions');

    $('.js-select').on('custom-select:change', function () {
      missionEl.html(missions[+$(this).find('option:selected').val()])
    });
  }
}
