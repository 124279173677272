import { redirectTo } from './redirectTo';

export const downloadPdf = () => {
  const redirectEl = $('[data-redirect]');
  const redirectUrl = redirectEl.data('redirect-url');
  const redirectDelay = +redirectEl.data('redirect-delay');
  const successMessageEl = $(redirectEl.data('success-element'));

  redirectEl.on('click', () => {
    redirectEl.addClass('hidden');
    successMessageEl.removeClass('hidden');
    redirectTo(redirectUrl, redirectDelay)
  })
}