export function showMoreReviews() {
  const showMoreBtn = $('.js-review-show-more');
  const reviewWrappers = $('.js-review-more');
  const buttonTextShow = showMoreBtn.text();
  const buttonTextHide = showMoreBtn.data('hide-text');

  showMoreBtn.on('click', function(e) {
    e.preventDefault();

    if(showMoreBtn.hasClass('is-open')) {
      showMoreBtn.removeClass('is-open').text(buttonTextShow);
      reviewWrappers.addClass('hidden');
    } else {
      showMoreBtn.addClass('is-open').text(buttonTextHide);
      reviewWrappers.removeClass('hidden');
    }
  });
}
