import { BODY } from '../_constants';
class SelectTabs {
  constructor(config) {
    this.elem = $(config);
    this.options = this.elem.find('option');
    this.init();
  }
  init() {
    this._createWrapper();
    this._createElement();
    this._change();
  }
  _createWrapper() {
    this.elem.wrap(`<div class="${SelectTabs.classes.wrapper}"></div>`);
  }
  get _parent() {
    return this.elem.closest(`.${SelectTabs.classes.wrapper}`);
  }
  
  // generate elements for custom select
  _createElement() {
    let self = this;
    this._parent.append(`<ul class="${SelectTabs.classes.list}"></ul>`);
    this.options.each((i, elem) => {
      let optionValue = $(elem).html();
      this._parent.find(`.${SelectTabs.classes.list}`).append(`<li>${optionValue}</li>`);
      this._parent.find(`.${SelectTabs.classes.list} li`).eq(Math.floor(self.options.length/2)).addClass(SelectTabs.classes.active);
    });
  }
  get _children() {
    return this._parent.find('li');
  }
  
  // change attributes after clicked option
  _change() {
    this._children.on('click', (e) => {
      let that = $(e.currentTarget);
      let index = that.index();
      that.addClass(SelectTabs.classes.active).siblings().removeClass(SelectTabs.classes.active);
      this.options.eq(index)
        .attr('selected', true)
        .siblings()
        .removeAttr('selected');
    });
  }
  
}
SelectTabs.classes = {
  wrapper: 'custom-select-tabs',
  dropdown: 'custom-select-tabs__dropdown',
  list: 'custom-select-tabs__list',
  active: 'is-active'
};
$.fn.customSelectTabs = function() {
  this.each((i, el) => new SelectTabs($(el)));
  return this;
};

$('.js-select-tabs').customSelectTabs();
