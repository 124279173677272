export function showMore() {
  let textBlocks = $('.js-show-all-text');
  let toggleBtn = $('.js-show-more-btn');

  var scrollTimeout;
  var throttle = 250;
  $(window).on('resize', function() {
    if (!scrollTimeout) {
      scrollTimeout = setTimeout(function() {
        textBlocks.each(function() {
          if (this.scrollHeight > $(this).innerHeight()) {
            $(this).find('.js-show-more-btn').show();
          } else {
            $(this).find('.js-show-more-btn').hide();
          }
        });
        scrollTimeout = null;
      }, throttle);
    }
  });

  textBlocks.each(function() {
    if (this.scrollHeight > $(this).innerHeight()) {
      $(this).find('.js-show-more-btn').show();
    } else {
      $(this).find('.js-show-more-btn').hide();
    }
  });
  
  toggleBtn.on('click', function(e) {
    e.preventDefault();
    let textBlock = $(this).closest('.js-show-all-text');
    var linkText = $(this).text().toUpperCase();  
    if(!textBlock.hasClass('is-open')) {
      linkText = 'show less';
      textBlock.addClass('is-open');
      $(this).addClass('is-open');
    } else {
      linkText = 'show more';
      textBlock.removeClass('is-open');
      $(this).removeClass('is-open');
    };
    
    $(this).text(linkText); 

  });
}
