export function storeMessageContent() {
  let submitBtn = $('.user-description__btn > .btn')

  submitBtn.on('click', function() {
    sessionStorage.setItem('message', $('#message_content').val());
  })

  if (sessionStorage.getItem('message')){
    $('#message_content').val(sessionStorage.getItem('message'))
  }

  if (document.referrer.includes('/messages/') && window.location.href.includes('/listings/')) {
    sessionStorage.clear();
  }
}
