import { BODY } from '../_constants';
export default class Select {
  constructor(config) {
    this.elem = $(config);
    this.options = this.elem.find('option');
    this.init();
  }
  init() {
    this._createWrapper();
    this._createElement();
    this._show();
    this._hide();
    this._placeholder();
    this._change();
    this._icon();
    // this._setLabel();
  }
  _createWrapper() {
    if (!this._parent.hasClass(Select.classes.wrapper)) {
      this.elem.wrap(`<div class="${Select.classes.wrapper}"></div>`);
    }
  }
  get _parent() {
    return this.elem.closest(`.${Select.classes.wrapper}`);
  }
  get _fieldText() {
    return this._parent.find(`.${Select.classes.text}`);
  }
  // generate elements for custom select
  _createElement() {
    let selectedArray = [];
    this._parent.append(`<div class="${Select.classes.field}"></div>`);
    this._parent.find(`.${Select.classes.field}`).append(`<div class="${Select.classes.text}"></div>`);
    this._parent.append(`<div class="${Select.classes.dropdown}"></div>`);
    this._parent.find(`.${Select.classes.dropdown}`).append(`<ul class="${Select.classes.list}"></ul>`);
    this.options.each((i, elem) => {
      let optionLabel = $(elem).html();
      let value = $(elem).val();
      this._parent.find(`.${Select.classes.list}`).append(`<li data-value="${value}">${optionLabel}</li>`);

      if (elem.getAttribute('selected')) {
        this._parent.find(`.${Select.classes.list} li`).eq(i).addClass(Select.classes.active);
        selectedArray.push(1);
      }

      if (elem.disabled) {
        this._parent.find(`.${Select.classes.list} li`).eq(i).addClass(Select.classes.disabled);
      }

    });

    if (selectedArray.length === 0) {
      this._parent.find(`.${Select.classes.list} li`).eq(0).addClass(Select.classes.active);
      this.options.eq(0).attr('selected', true);
    }
  }
  get _children() {
    return this._parent.find('li');
  }
  get _field() {
    return this._parent.find(`.${Select.classes.field}`);
  }
  // show dropdown
  _show() {
    this._field.on('click', () => {
      if(!this._parent.hasClass(Select.classes.open)) {
        $(`.${Select.classes.wrapper}`).removeClass(Select.classes.open);
        this._parent.addClass(Select.classes.open);
      } else {
        this._parent.removeClass(Select.classes.open);
      }
    });
  }
  // close dropdown
  _hide() {
    BODY.click((e) => {
      if (!$(e.target).closest(`.${Select.classes.wrapper}`).length) {
        $(`.${Select.classes.wrapper}`).removeClass(Select.classes.open);
      }
    });
  }
  // generate text for select input field
  _placeholder() {
    let selectedArray = [];
    this.options.each((i, elem) => {
      if (elem.getAttribute('selected')) {
        this._parent.find(`.${Select.classes.text}`).text(this.options.eq(i).text()).addClass('is-placeholder');
        selectedArray.push(1);
      }
    });

    if (selectedArray.length === 0) {
      this._parent.find(`.${Select.classes.text}`).text(this.options.eq(0).text()).addClass('is-placeholder');
    }
  }
  // change attributes after clicked option
  _change() {
    this._children.on('click', (e) => {
      let that = $(e.currentTarget);
      let text = that.text();
      let data_value = that.data('value');
      let index = that.index();
      if (!that.hasClass('disabled')) {
        that.addClass(Select.classes.active).siblings().removeClass(Select.classes.active);
        this.options.eq(index)
          .attr('selected', true)
          .val(data_value)
          .siblings()
          .removeAttr('selected')
          .trigger('custom-select:change')
        this._fieldText.html(text);
        this._fieldText.removeClass('is-placeholder');
        this._parent.removeClass(Select.classes.open);
        if(index === 0) {
          this._parent.find(`.${Select.classes.text}`).addClass('is-placeholder');
        } else {
          this._parent.find(`.${Select.classes.text}`).removeClass('is-placeholder');
        }
      }
    });
  }
  _icon() {
    if(this.elem.data('icon')) {
      let iconName = this.elem.data('icon');
      let iconHtml = `<svg class="icon icon-${iconName}"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/sprite.svg#icon-${iconName}"></use></svg>`;
      this._parent.find(`.${Select.classes.field}`).append(iconHtml);
    }
  }
  _setLabel() {
    let arrSearch = window.location.search.split('?');
    var params = [];

    if (arrSearch[1]) {
      let arrParams = arrSearch[1].split('&');

      $.each(arrParams, function(index, param){
        let keyValue = param.split('=')
        params.push({selectId: keyValue[0], value: keyValue[1]});
      });
    }

    let options = $(this.elem).find('option');
    if (params.length) {
      options.each(function () {
        for (let i = 0; i < params.length; i++) {
          let id = $(this).closest('.js-select').attr('id');
          let value = this.value;
          let text = this.text;

          if (value === params[i].value && id === params[i].selectId) {
            $(this)
              .attr('selected', true)
              .val(value)
              .text(text)
              .siblings()
              .removeAttr('selected')
            $(this).closest('.js-select')
              .siblings(`.${Select.classes.field}`)
              .find(`.${Select.classes.text}`)
              .html(text);
            let listItem = $(this).closest(`.${Select.classes.wrapper}`).find('li');
            listItem.each(function () {
              if ($(this).text() === text) {
                $(this).addClass(Select.classes.active).siblings().removeClass(Select.classes.active);
              }
            });
          }
        }
      });
    }
  }
}
Select.classes = {
  wrapper: 'custom-select',
  field: 'custom-select__field',
  text: 'custom-select__text',
  dropdown: 'custom-select__dropdown',
  list: 'custom-select__list',
  active: 'is-active',
  open: 'is-open',
  disabled: 'disabled'
};
$.fn.customSelect = function() {
  this.each((i, el) => new Select($(el)));
  return this;
};

$('.js-select').customSelect();
