export function video() {
  var tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  function callPlayer(frame_id, func, args) {
    if (window.jQuery && frame_id instanceof jQuery)
      frame_id = frame_id.get(0).id;
    var iframe = document.getElementById(frame_id);
    if (iframe && iframe.tagName.toUpperCase() !== 'IFRAME') {
      iframe = iframe.getElementsByTagName('iframe')[0];
    }

    // When the player is not ready yet, add the event to a queue
    // Each frame_id is associated with an own queue.
    // Each queue has three possible states:
    //  undefined = uninitialised / array = queue / 0 = ready
    if (!callPlayer.queue)
      callPlayer.queue = {};
    var queue = callPlayer.queue[frame_id],
      domReady = document.readyState === 'complete';

    if (domReady && !iframe) {
      // DOM is ready and iframe does not exist. Log a message
      window.console &&
        console.log('callPlayer: Frame not found; id=' + frame_id);
      if (queue)
        clearInterval(queue.poller);
    } else if (func === 'listening') {
      // Sending the "listener" message to the frame, to request status updates
      if (iframe && iframe.contentWindow) {
        func = '{"event":"listening","id":' + JSON.stringify('' + frame_id) + '}';
        iframe.contentWindow.postMessage(func, '*');
      }
    } else if (!domReady || iframe && (!iframe.contentWindow || queue && !queue.ready) ||
      (!queue || !queue.ready) && typeof func === 'function'
    ) {
      if (!queue)
        queue = callPlayer.queue[frame_id] = [];
      queue.push([func, args]);
      if (!('poller' in queue)) {
        // keep polling until the document and frame is ready
        queue.poller = setInterval(
          function() {
            callPlayer(frame_id, 'listening');
          },
          250
        );
        // Add a global "message" event listener, to catch status updates:
        messageEvent(
          1,
          function runOnceReady(e) {
            if (!iframe) {
              iframe = document.getElementById(frame_id);
              if (!iframe)
                return;
              if (iframe.tagName.toUpperCase() !== 'IFRAME') {
                iframe = iframe.getElementsByTagName('iframe')[0];
                if (!iframe)
                  return;
              }
            }
            if (e.source === iframe.contentWindow) {
              // Assume that the player is ready if we receive a
              // message from the iframe
              clearInterval(queue.poller);
              queue.ready = true;
              messageEvent(0, runOnceReady);
              // .. and release the queue:
              while (tmp = queue.shift()) {
                callPlayer(frame_id, tmp[0], tmp[1]);
              }
            }
          },
          false
        );
      }
    } else if (iframe && iframe.contentWindow) {
      // When a function is supplied, just call it (like "onYouTubePlayerReady")
      if (func.call)
        return func();
      // Frame exists, send message
      iframe.contentWindow.postMessage(
        JSON.stringify({
          'event': 'command',
          'func': func,
          'args': args || [],
          'id': frame_id
        }),
        '*'
      );
    }
    /* IE8 does not support addEventListener... */
    function messageEvent(add, listener) {
      var w3 = add ? window.addEventListener : window.removeEventListener;
      w3
        ?
        w3('message', listener, !1) :
        (add ? window.attachEvent : window.detachEvent)('onmessage', listener);
    }
  }

  function onYouTubeIframeAPIReady() {
    $('.js-video').each(function(index, el) {
      let id = $(this).attr('id');
      let video = $(this).data('video');           
      
      let player = new YT.Player(id, {
        height: '100%',
        width: '100%',
        videoId: video,
        // youtube video id
        playerVars: {
          'autoplay': 0,
          'rel': 0,
          'showinfo': 0,
          'loop': 1,
          'controls': 1
        },
        events: {
          'onStateChange': onPlayerStateChange,
          'onReady': onPlayerReady
        }
      });
    });

    $('.js-video-in-popup').each(function(index, el) {
      $('body').on('click', '.js-autoplay-video', function(event) {
        event.preventDefault();
        
        let id = $(this).closest('.js-popup-video').data('video-id');   
        let video = $(this).closest('.js-popup-video').data('popup-video');      

        let popupVideo = $('.js-video-in-popup'); 
        popupVideo[0].setAttribute('data-video', video);
        popupVideo[0].setAttribute('id', id);
        
  
        let player = new YT.Player(id, {
          height: '100%',
          width: '100%',
          videoId: video,
          // youtube video id
          playerVars: {
            'autoplay': 1,
            'rel': 0,
            'showinfo': 0,
            'loop': 1,
            'controls': 1
          },
          events: {
            'onStateChange': onPlayerStateChange,
            'onReady': onPlayerReady
          }
        });        
      });
    });
  }

  function onPlayerStateChange(event) {
    if (event.data === YT.PlayerState.ENDED) {
      $(event.target.a).next().fadeIn('normal');
      $(event.target.a).parent().find('.js-start-video').show();
      $(event.target.a).parent().find('.js-video-bg').show();
    }
    if (event.data === 2) {
      $(event.target.a).next().fadeIn('normal');
      $(event.target.a).parent().find('.js-start-video').show();
      $(event.target.a).parent().find('.js-video-bg').show();
    }
  }

  function onPlayerReady() {
    $('body').on('click', '.js-start-video', function(event) {
      event.preventDefault();
      $(this).closest('.video').find('.js-start-video').hide();
      $(this).closest('.video').find('.js-video-bg').hide();
      let idd = $(this).parent().find('.js-video').attr('id');
      
      $(`#${idd}`).show();
      callPlayer(idd, 'playVideo');
      $(this).closest('.video').removeClass('is-preview');
    });

  }

  window.onload = () => {
    onYouTubeIframeAPIReady();

    const p = document.getElementById('player');
    $(p).hide();
    $('.video').addClass('is-bg');
  };
}
