export function toggleWishList() {
    $('.like-btn').on('click', function(){
        var like_btn = $(this);
        var listing_id = like_btn.attr('data-listing-id');
        if(like_btn.hasClass('is-active')){
            $.ajax({
                type: "POST",
                url: '/remove_from_favorite_list',
                data: { listing_id: listing_id },
                complete: function(data){
                    if (data.status == 201){
                        like_btn.removeClass('is-active')
                    }
                }
            });
        }else{
            $.ajax({
                type: "POST",
                url: '/add_to_favorite_list',
                data: { listing_id: listing_id },
                complete: function(data){
                    if (data.status == 201){
                        like_btn.addClass('is-active')
                    }
                }
            });
        }

    })
}
