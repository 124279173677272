export function payments() {

  let paymentPaypal = $('.js-payment-paypal');
  let paymentCard = $('.js-payment-card');
  let paymentMethods = $('.js-payment-methods');

  $('.js-payment-method-paypal').on( 'click', function() {
    if ($('.js-payment-method-paypal input[type=radio]').is(':checked')) {
      paymentMethods.hide();
      paymentPaypal.show();
    } 
  });
  $('.js-payment-method-card').on( 'click', function() {
    if ($('.js-payment-method-card input[type=radio]').is(':checked')) {
      paymentMethods.hide();
      paymentCard.show();
    } 
  });


  $('.js-payment-paypal-change').on( 'click', function() {
    paymentPaypal.hide();
    paymentCard.show();
  });

  $('.js-payment-card-change').on( 'click', function() {
    paymentCard.hide();
    paymentPaypal.show();
  });

};
