export function checkboxToggle() {

  function toggleCheckBox(el) {
    let checkbox = $(el).closest('.checkbox').find('input[type=checkbox]');
    let input_value = $(el).val()
    if(input_value.length > 0) {
      $(checkbox).prop('checked', true);
      $(checkbox).prop('value', input_value);
    } else {
      $(checkbox).prop('checked', false);
    }
  }

  const checkboxes = [].slice.call(document.querySelectorAll('.js-checkbox-input'));
  
  checkboxes.forEach(function(el) {

    el.addEventListener('input', function() {
      toggleCheckBox(el);
    }, false);
    
  });
}
