export function addressAutocomplete() {
  let autocomplete = $('.js-geocomplete');

  if (autocomplete.length > 0) {
    autocomplete.each(function () {
      let $this = $(this)

      let latInput = $this.parent().find('.js-geocomplete-lat');
      let lngInput = $this.parent().find('.js-geocomplete-lng');
      let cityInput = $this.parent().find('.js-geocomplete-city');
      let stateInput = $this.parent().find('.js-geocomplete-state');
      let searchBox = new google.maps.places.SearchBox(this);

      $this.on('click', () => {
        if (latInput.val().length > 0 && lngInput.val().length > 0 || $this.val().length > 0) {
          $this.val('')
          latInput.val('')
          lngInput.val('')
          cityInput.val('')
          stateInput.val('')
        }
      });

      autocomplete.on('keyup', e => {
        if(e.which === 13) {
          if (latInput.val().length === 0 && lngInput.val().length === 0) {
            e.preventDefault()
          } else {
            autocomplete.closest('form').submit()
          }
        }
      });

      searchBox.addListener('places_changed', function () {

        let placesResult = searchBox.getPlaces();

        if (placesResult.length === 0) return;

        let typesResult = []
        let places = placesResult[0]

        latInput.val(places.geometry.location.lat());
        lngInput.val(places.geometry.location.lng());

        // create array of types as key and places.address_components index as value
        places.address_components.map((item, index) => {
          typesResult[item.types[0]] = index
        })

        // set values to state or/and city
        if (places.types[0] === 'administrative_area_level_1') {
          stateInput.val(places.address_components[typesResult['administrative_area_level_1']].short_name)
        } else if (places.types[0] === 'locality') {
          cityInput.val(places.address_components[typesResult['locality']].long_name)
        } else {
          stateInput.val('')
          cityInput.val('')
        }
      });
    });
  }
}
