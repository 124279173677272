import { ISACTIVE } from '../_constants';

export function checkSelectFilled() {
//   let mainBlock = $('.js-booking-request');
//
//   let datepickerInputs = $('.js-datepicker-value').find('input');
//   let dropdowns = mainBlock.find('.custom-select__list');
//   let requestTimeToggle = $('.js-toggle-time-selects');
//
//
//   dropdowns.on('click', function() {
//     let selectsBlock = $(this).closest(mainBlock);
//     checkSelected(selectsBlock);
//   });
//   datepickerInputs.on('change', function() {
//     let selectsBlock = $(this).closest(mainBlock);
//     checkSelected(selectsBlock);
//   });
//
//   requestTimeToggle.on('click', function() {
//     let btnAttr = $(this).attr('data-select-time-target');
//     requestTimeToggle.addClass(ISACTIVE);
//     $(this).removeClass(ISACTIVE);
//     let selectsBlock = $(this).closest(mainBlock).find('.js-check-select-values');
//     let thisBlock = $(this).closest(mainBlock);
//     selectsBlock.each(function() {
//       let selectBlocksAttr = $(this).attr('data-select-time');
//       if(selectBlocksAttr === btnAttr) {
//         selectsBlock.removeClass(ISACTIVE);
//         $(this).addClass(ISACTIVE);
//       }
//     });
//     checkSelected(thisBlock);
//   });
// }
//
// function checkSelected(thisBlock) {
//
//   let requestBtn = $('.js-request-btn');
//   let pricingBlock = $('.js-show-cost');
//   let selects = $(thisBlock).find('.js-check-select-values.is-active').find('select');
//
//   let datepickers = $(thisBlock).find('.js-check-select-values.is-active').find('.js-datepicker-value');
//   let selectedArr = [];
//   selects.each(function() {
//     let allSelected = $(this).find('option:selected').attr('selected');
//     if(allSelected !== undefined) {
//       selectedArr.push(allSelected);
//     }
//   });
//
//   datepickers.each(function() {
//     let dateInputs = $(this).find('input').eq(0).val();
//     if(dateInputs.length !== 0) {
//       selectedArr.push(dateInputs);
//     }
//   });
//
//
//   if(selectedArr.length === (selects.length + datepickers.length)) {
//     requestBtn.removeClass('is-disabled');
//     requestBtn.prop('disabled', false);
//     pricingBlock.addClass('is-shown');
//   } else {
//     requestBtn.addClass('is-disabled');
//     requestBtn.prop('disabled', true);
//     pricingBlock.removeClass('is-shown');
//   }
}

