export function copyToClipboard(selector) {
  if (document.selection) { 
    var range = document.body.createTextRange();
    range.moveToElementText(selector);
    range.select().createTextRange();
    document.execCommand('copy'); 

  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(selector);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }}
