export function counter() {
  let counters = $('.js-counter');
  let increment = $(counters).find('.js-counter-increment');
  let decrement = $(counters).find('.js-counter-decrement');
  var timer = 1;
  var timerInterval;
  let amountInput = counters.find('.js-counter-amount-input');
  let allowedMinHour = +counters.data('allowed-min-hour');
  let errorBlock = $('.js-counter-error');

  if (amountInput.val()) {
    $('.js-counter-amount').text(amountInput.val())
  } else {
    amountInput.val(0);
    $('.js-counter-amount').text(0);
  }

  /***** increment *****/
  $(increment).on('click', function() {
    let amountBlock = $(this).siblings('.js-counter-amount');
    incrementCounter(amountBlock);
  });

  $(increment).on('mousedown', function() {
    let amountBlock = $(this).siblings('.js-counter-amount');
    timerInterval = setInterval(function() {
      incrementCounter(amountBlock);
    }, 300);
  });

  $(increment).on('mouseup', function() {
    clearInterval(timerInterval);
    timer = 1;
  });
  /*******************/


  /***** decrement *****/
  $(decrement).on('click', function() {
    let amountBlock = $(this).siblings('.js-counter-amount');
    decrementCounter(amountBlock);
  });

  $(decrement).on('mousedown', function() {
    let amountBlock = $(this).siblings('.js-counter-amount');
    timerInterval = setInterval(function() {
      decrementCounter(amountBlock);
    }, 300);
  });

  $(decrement).on('mouseup', function() {
    clearInterval(timerInterval);
    timer = 0;
  });
  /*******************/

  $(document).on('mouseup', function() {
    clearInterval(timerInterval);
    timer = 1;
  });

  function incrementCounter(el) {
    $('.counter__wrapper .field__error-message').css('display', 'none');
    let amount = parseInt($(el).text());
    if(amount >= 0) {
      if (amount === allowedMinHour) {
        errorBlock.html('Minimal Rental Hours cannot be more than minimum venue availability hours noted in previous screen');
        return;
      }
      amount += 1;
      $(el).text(amount);
      amountInput.val(amount);
      errorBlock.html('');
    } else {
      return;
    }
  }
  function decrementCounter(el) {
    let amount = parseInt($(el).text());
    if(amount > 0) {
      if (amount === 1) {
        return;
      }
      amount -= 1;
      $(el).text(amount);
      amountInput.val(amount);
      errorBlock.html('');
    } else {
      return;
    }
  }
}
