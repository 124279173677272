// v1 scripts
import { accordion } from './components/accordion.js';
import { counter } from './components/counter.js';
import { downloadPdf } from './components/downloadPdf';
import { forbidAllCaps } from './components/forbidAllCaps';
import { loading } from './components/loading.js';
import { onlyNumbers } from './components/onlyNumbers.js';
import { setTextareaHeight } from './components/setTextareaHeight.js';
import { sharePopup } from './components/sharePopup.js';
import { showMore } from './components/showMore.js';
import { storeMessageContent } from './components/storeMessageContent';
import { submitFormStatus } from './components/submitFormStatus.js';
import { submitSearchChatForm } from './components/submitSearchChatForm.js';
import { timeZoneDetector } from './components/timeZoneDetector';
import { togglePayout } from './components/togglePayout.js';
import { validateEmptyMessage } from './components/validateEmptyMessage.js';
import { viewportHeight } from './components/viewportHeight.js';

// v2 scripts
import './components/customSelect.js';
import './components/customSelectTabs.js';
import { additionalFilters } from './components/additionalFilters.js';
import { addressAutocomplete } from './components/addressAutocomplete.js';
import { benefitingShowMission } from './components/benefitingShowMission';
import { bookingCountdown } from './components/bookingCountdown';
import { calculateBookingPrice } from './components/datepicker/calculateBookingPrice.js';
import { checkboxToggle } from './components/checkboxToggle.js';
import { checkSelectFilled } from './components/checkSelectFilled.js';
import { clickFunctions } from './components/clickFunc.js';
import { copyLink } from './components/copyLink.js';
import { datePicker } from './components/datepicker/datePicker.js';
import { datePickerBooking } from './components/datepicker/datePickerBooking.js';
import { datePickerBookingToggle } from './components/datepicker/datePickerBookingToggle.js';
import { emailSubscription } from './components/emailSubscription.js';
import { expander } from './components/expander.js';
import { feedback } from './components/feedback';
import { filteringDropDown } from './components/filteringDropDown.js';
import { fixedHeaderOnScroll } from './components/fixedHeaderOnScroll.js';
import { infoModal } from './components/infoModal.js';
import { initMap } from './components/initMap.js';
import { initStripe } from './components/initStripe';
import { initVideos } from './components/initVideos.js';
import { inputDropdown } from './components/inputDropdown.js';
import { mailchimp } from './components/mailchimp.js';
import { mainSearch } from './components/mainSearch.js';
import { mask } from './components/mask';
import { passwordInput } from './components/passwordInput.js';
import { payments } from './components/payments.js';
import { popups } from './components/popup.js';
import { reCAPTCHA } from './components/reCAPTCHA.js';
import { reinitVideoPopup } from './components/reinitVideoPopup.js';
import { showFilters } from './components/showFilters.js';
import { showMoreReviews } from './components/showMoreReviews.js';
import { sliders } from './components/slider.js';
import { tabs } from './components/tabs.js';
import { textareaCounter } from './components/textareaCounter.js';
import { toggleContent } from './components/toggleContent.js';
import { toggleMapSwitch } from './components/toggleMapSwitch.js';
import { toggleWishList } from './components/toggleWishList.js';
import { validateBenefiting } from './components/validateBenefiting';
import { ValidationForm } from './components/ValidationForm.js';
import { video } from './components/video.js';

$(document).ready(() => {
  $(function() {
// v1 scripts
    accordion();
    counter();
    downloadPdf();
    forbidAllCaps();
    loading();
    onlyNumbers();
    setTextareaHeight();
    sharePopup();
    showMore();
    storeMessageContent();
    submitFormStatus();
    submitSearchChatForm();
    timeZoneDetector();
    togglePayout();
    validateEmptyMessage();
    viewportHeight();

// v2 scripts
    additionalFilters();
    addressAutocomplete();
    benefitingShowMission();
    bookingCountdown();
    calculateBookingPrice();
    checkboxToggle();
    checkSelectFilled();
    clickFunctions();
    copyLink();
    datePicker();
    datePickerBooking();
    datePickerBookingToggle();
    emailSubscription();
    expander();
    feedback();
    filteringDropDown();
    fixedHeaderOnScroll();
    infoModal();
    initStripe();
    initVideos();
    inputDropdown();
    mailchimp();
    mainSearch();
    mask();
    passwordInput();
    payments();
    popups();
    reinitVideoPopup();
    showFilters();
    showMoreReviews();
    sliders();
    tabs();
    textareaCounter();
    toggleContent();
    toggleMapSwitch();
    toggleWishList();
    validateBenefiting();
    ValidationForm();
    video();
  });
});

window.reCAPTCHA = reCAPTCHA;
