import { ISOPEN } from '../_constants';

export function accordion() {
  let accordionToggle = $('.js-toggle-accordion');
  let accordions = $(accordionToggle).closest('.js-accordions').find('.js-accordion-item');

  $(accordionToggle).on('click', function() {
    var currentItem = $(this).closest('.js-accordion-item');
    var currentContent = $(this).closest('.js-accordion-item').find('.js-accordion-content');
    $(accordions).find('.js-accordion-content').not(currentContent).slideUp();
    $(accordions).not(currentItem).removeClass(ISOPEN);
    $(currentItem).toggleClass(ISOPEN);
    $(currentContent).slideToggle();
    return false;
  });
}
