import { scrollTo } from './scrollTo.js'

export function validateBooking() {
  let eventTypeInput = $('.js-select');
  let peopleInput = $('.js-requested-people');
  let messageInput = $('.js-booking-message');
  let errorMsg = 'This field is required';
  let errorMsgMaxPeople = 'Please enter number less than ';
  let eventTypeInputWrapper = eventTypeInput.closest('.select');
  let peopleInputWrapper = peopleInput.closest('.field_secondary');
  let messageInputWrapper = messageInput.closest('.user-description__textarea');
  let preventMultipleScroll = [];

  function validateField(element, wrapper) {
    if (!element.val().length) {
      if (!wrapper.hasClass('is-error')) {
        wrapper.addClass('is-error').append(`<div class="field__error-message js-validation-error">${errorMsg}</div>`);
      }
      if (!preventMultipleScroll.length) {
        scrollTo(wrapper, 400);
      }
      preventMultipleScroll.push('1');
    } else if (+element.val() > +element.attr('max')) {
      if (!wrapper.hasClass('is-error')) {
        wrapper.addClass('is-error').append(`<div class="field__error-message js-validation-error">${errorMsgMaxPeople + element.attr('max')}</div>`);
      }
      if (!preventMultipleScroll.length) {
        scrollTo(wrapper, 400);
      }
      preventMultipleScroll.push('1');
    } else {
      wrapper.removeClass('is-error').find('.js-validation-error').remove();
    }
  }

  validateField(eventTypeInput, eventTypeInputWrapper);
  validateField(peopleInput, peopleInputWrapper);
  validateField(messageInput, messageInputWrapper);

  peopleInput.on('input', function () {
    validateField(peopleInput, peopleInputWrapper);
  });

  messageInput.on('input', function () {
    validateField(messageInput, messageInputWrapper);
  });

  eventTypeInput.on('custom-select:change', function () {
    validateField(eventTypeInput, eventTypeInputWrapper);
  });

  if (preventMultipleScroll.length) {
    return false
  } else {
    return true
  }
}