export const feedback = () => {
  const form = $('.js-feedback-form');
  const requiredFields = $('.js-feedback-required');
  const submitBtn = $('.js-feedback-submit');
  let errorsArray = [];

  if (form.length > 0) {
    function validateFeedbackForm() {
      errorsArray = [];
      requiredFields.each((index, input) => {
        if ($(input).val().length === 0 ) {
          const errorMessage = $(input).hasClass('js-recaptcha') ? 'Please prove that you are not a Robot' : 'Can\'t be blank';

          $(input).closest('.field')
            .addClass('is-error')
            .find('.js-feedback-error').text(errorMessage);

          errorsArray.push('1')
        } else {
          if ($(input).hasClass('js-feedback-email')) {
            validateEmail($(input));
          } else {
            $(input).closest('.field')
              .removeClass('is-error')
              .find('.js-feedback-error').text('');
          }
        }
      });
    }

    submitBtn.on('click', e => {
      e.preventDefault();

      validateFeedbackForm();
      if (errorsArray.length === 0) form.submit()
    });

    requiredFields.each((index, item) => {
      $(item).on('input captcha-change', () => validateFeedbackForm())
    });

    function validateEmail(input) {
      errorsArray = [];
      let filter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(input.val())) {
        input.closest('.field').addClass('is-error').find('.js-feedback-error').text('Please provide a valid email address');
        errorsArray.push('1')
      } else {
        input.closest('.field').removeClass('is-error').find('.js-feedback-error').text('');
      }
    }
  }
};