export function toggleContent() {
  $('.js-toggle').each(function () {
    let $this = $(this);
    let buttonInitialText = $this.text();
    let buttonAlternativeText = $this.data('alternative-text');

    $this.click(function (e) {
      e.preventDefault();
      let target = $this.parent().find('.js-toggle-target');

      if (target.hasClass('is-open')) {
        target.removeClass('is-open');
        $this.text(buttonInitialText).removeClass('is-open');
      } else {
        target.addClass('is-open');
        $this.text(buttonAlternativeText).addClass('is-open');
      }
    });
  });
}