import { WINDOW } from '../_constants';

export function setTextareaHeight() {
  function adjustHeight(textareaElement, minHeight) {

    let outerHeight = parseInt(window.getComputedStyle(textareaElement).height, 10);
    let diff = outerHeight - textareaElement.clientHeight;


    if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      textareaElement.style.height = Math.max(minHeight, textareaElement.scrollHeight + diff) + 'px';
    } else {
      textareaElement.style.height = 0;
      textareaElement.style.height = Math.max(minHeight, textareaElement.scrollHeight + diff) + 'px';
    };   
      
    let parentAccordionItem = textareaElement.closest('[data-accordion-item]');
    if (parentAccordionItem && parentAccordionItem.classList.contains('is-active') && parentAccordionItem.style.maxHeight !== 'none') {
      parentAccordionItem.style.maxHeight = 'none';
    };
    let textareaPlaceholder = $(textareaElement).is('[v-center]');
    if(textareaPlaceholder) {
      $(textareaElement).addClass('is-center');
    }
  };
  
  const textAreas = [].slice.call(document.querySelectorAll('textarea[data-adaptheight]'));
  
  textAreas.forEach(function(el) {
  
    let minHeight = el.scrollHeight;
  
    el.addEventListener('input', function() {
      adjustHeight(el, minHeight);
    }, false);
  
    el.addEventListener('focus', function() {
      let parent = el.parentNode;
      parent.classList.add('has-focus');
    });
  
    el.addEventListener('blur', function() {
      let parent = el.parentNode;
      parent.classList.remove('has-focus');
    });
    

    var scrollTimeout;
    var throttle = 50;

    WINDOW.on('resize', function() {
      if (!scrollTimeout) {
        scrollTimeout = setTimeout(function() {
          adjustHeight(el, minHeight);
          scrollTimeout = null;
        }, throttle);
      }
    });

    $(document).ready(() => {
      adjustHeight(el, minHeight);
    });
  
  });
};

