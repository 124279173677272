export function initVideos() {
  let videos = document.querySelectorAll('.js-video');
  let popupVideosLink = document.querySelectorAll('.js-popup-video');

  videos = [...videos];
  popupVideosLink = [...popupVideosLink];

  for(let i=0; i<videos.length; i++) {
    videos[i].id = 'player' + (i+1);
  }

  for(let i=0; i<popupVideosLink.length; i++) {
    popupVideosLink[i].setAttribute('data-video-id', 'player' + (i+1+videos.length));
  }
}
